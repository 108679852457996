import i18n from 'i18n'
import { Matrix3, Euler, Vector3 } from 'math-ds'
import { radiansToDegrees } from '@turf/turf'
// turf
// Project deps
// import { getArtifacts } from '../modules/projects/selectors'
// Local deps
import { DataType } from 'types/form'
import { getCloneJobOptionValue, getTrajectoryArtifacts, isTrajectoryArtifactHasSameIMUOrientation, areSameIMUOrientations, safeToString } from '../utils'
import { MapBackendNameToFrontend } from '../constants'
import { getMissions } from 'modules/projects/selectors'
import { makeUnique } from 'utils/list'
import { makeZXYRotationMatrix, getRotationMatrixFromMatrix3, fromYPR, getMatrix3FromArray, getPlsToNovAtelVehicle } from 'utils/calibrationconverter'

export default {
  warning: {
    dataType: DataType.TEXT,
    optional: true,
    invisible: (state, values) => {
      const warningMessage = values.warning
      return !warningMessage
    },
    variant: 'warning',
    sendToBackend: false,
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const orientation = getCloneJobOptionValue(state, artifactId, options, 'orientation')
        if (orientation) return ''
      }
      const trajectoryArtifacts = getTrajectoryArtifacts(state)
      const isSameIMU = isTrajectoryArtifactHasSameIMUOrientation(trajectoryArtifacts)
      if (isSameIMU) {
        return ''
      }
      const firstTrajectoryArtifactWithIMU = trajectoryArtifacts.find(artifact => {
        const imuOrientation = artifact.properties.imu_orientation
        return Boolean(imuOrientation)
      })
      if (firstTrajectoryArtifactWithIMU) {
        return i18n.t('templates.jobOptions.imu.warning.differentIMU', { name: firstTrajectoryArtifactWithIMU.name })
      }
      // If wasn't found any artifact with IMU orientation we can try to find imu orientation in mission's plp
      const allMissionIds = makeUnique(trajectoryArtifacts
        .filter(artifact => artifact.mission && artifact.mission.id)
        .map(artifact => artifact.mission.id),
      )
      const missions = getMissions(state)
      const imuOrientations = missions
        .filter(mission => allMissionIds.includes(mission.id) && Boolean(mission.plp))
        .map(mission => ({ mission, imuOrientation: getIMUOrientationFromPLP(mission.plp) }))
      const areSameIMU = areSameIMUOrientations(imuOrientations.map(({ imuOrientation }) => imuOrientation))
      if (areSameIMU) {
        return ''
      } else {
        const firstPLPImuOrientation = imuOrientations.find(({ imuOrientation }) => Boolean(imuOrientation))
        if (firstPLPImuOrientation) {
          return i18n.t('templates.jobOptions.imu.warning.differentPLPIMU', { name: firstPLPImuOrientation.mission.name })
        }
      }
      return i18n.t('templates.jobOptions.imu.warning.noIMU')
    },
  },
  // The following three fields specify the relative rotation of the Imu to the rest of the drone.
  orientation: {
    name: MapBackendNameToFrontend.bodyToImuRotation,
    componentNames: ['X', 'Y', 'Z'],
    dataType: DataType.VECTOR3,
    precision: [3, 3, 3],
    initialValue: (state, artifactId, options) => {
      const { extraProps } = options
      const { clone } = extraProps
      if (clone) {
        const orientation = getCloneJobOptionValue(state, artifactId, options, 'orientation')
        if (orientation) return orientation.map(safeToString)
      }
      const trajectoryArtifacts = getTrajectoryArtifacts(state)
      const isSameIMU = isTrajectoryArtifactHasSameIMUOrientation(trajectoryArtifacts)
      if (isSameIMU) {
        const [firstTrajectoryArtifact] = trajectoryArtifacts
        return firstTrajectoryArtifact.properties.imu_orientation.map(safeToString)
      }
      // If IMU orientations are not the same we should find first artifact with defined orientation
      const firstTrajectoryArtifactWithIMU = trajectoryArtifacts.find(artifact => {
        const imuOrientation = artifact.properties.imu_orientation
        return Boolean(imuOrientation)
      })
      if (firstTrajectoryArtifactWithIMU) {
        return firstTrajectoryArtifactWithIMU.properties.imu_orientation.map(safeToString)
      }
      // If wasn't found any artifact with IMU orientation we can try to find imu orientation in mission's plp
      const allMissionIds = makeUnique(trajectoryArtifacts
        .filter(artifact => artifact.mission && artifact.mission.id)
        .map(artifact => artifact.mission.id),
      )
      const missions = getMissions(state)
      const imuOrientations = missions
        .filter(mission => allMissionIds.includes(mission.id) && Boolean(mission.plp))
        .map(mission => mission.plp)
        .map(getIMUOrientationFromPLP)
        .filter(Boolean)
      const [firstPLPImuOrientation] = imuOrientations
      if (firstPLPImuOrientation) {
        return [firstPLPImuOrientation.x, firstPLPImuOrientation.y, firstPLPImuOrientation.z].map(safeToString)
      }
      return ['0', '0', '0']
    },
  },
}

function getIMUOrientationFromPLP (plp) {
  const rover = plp.settingsRover
  const navigationSystem = 'settingsRover' in plp && rover
    ? rover.navigationSystem
    : plp.navigationSystem
  let orientation = null
  // Process IMU
  const imu = navigationSystem.imu
  let mRotationPlsToImu = null
  // If there vehicleBodyRotation and imuOrientation inside configuration it's probably <=Se4 version
  if ('imuOrientation' in navigationSystem && 'vehicleBodyRotation' in navigationSystem) {
    let imuOrientation = navigationSystem.imuOrientation
    imuOrientation = typeof imuOrientation === 'number'
      ? imuOrientation
      : 1
    let vehicleBodyRotation = navigationSystem.vehicleBodyRotation
    vehicleBodyRotation = vehicleBodyRotation && typeof vehicleBodyRotation === 'object' && 'x' in vehicleBodyRotation && 'y' in vehicleBodyRotation && 'z' in vehicleBodyRotation
      ? vehicleBodyRotation
      : { x: 0, y: 0, z: 0 }
    const type = navigationSystem && navigationSystem.type && typeof navigationSystem.type === 'string'
      ? navigationSystem.type.toLowerCase()
      : ''
      // Set matrix rotation based on the type
    if (type.includes('oem7')) {
      // Transform to PLS to IMU frame
      mRotationPlsToImu = setNovAtelOem7(getNovAtelOem7(vehicleBodyRotation, imuOrientation))
      // Transform to PLS to IMU frame
    } else if (type.includes('oem6')) {
      mRotationPlsToImu = setNovAtelOem6(getNovAtelOem6(vehicleBodyRotation, imuOrientation))
    }
    if (mRotationPlsToImu) {
      orientation = getNovAtelInertialExplorer(undefined, mRotationPlsToImu) // getPls(mRotationPlsToImu)
    }
  } else if (imu && 'orientation' in imu) {
    if (imu.orientation && typeof imu.orientation === 'object') {
      const orient = getImuTransformedVectorRotationValues(imu.orientation)
      orientation = getNovAtelInertialExplorer(orient)
    }
  }
  return orientation
}

function getAsNumber (number) {
  return typeof number === 'number' ? number : 0
}

function getImuTransformedVectorRotationValues (transform) {
  if (!transform) return { x: 0, y: 0, z: 0 }
  return {
    x: 'rx' in transform ? getAsNumber(transform.rx) : getAsNumber(transform.x),
    y: 'ry' in transform ? getAsNumber(transform.ry) : getAsNumber(transform.y),
    z: 'rz' in transform ? getAsNumber(transform.rz) : getAsNumber(transform.z),
  }
}

const UpAxis = {
  XUp: 1, // the numbers are important, they match novatel's conventions
  XDown: 2,
  YUp: 3,
  YDown: 4,
  ZUp: 5,
  ZDown: 6,
}
/*
const UpAxisName = {
  1: 'XUp',
  2: 'XDown',
  3: 'YUp',
  4: 'YDown',
  5: 'ZUp',
  6: 'ZDown',
}
*/

function getNovAtelImuAxisRemapping (upAxis) {
  // This rotation is from the physical IMU frame to the axis-remapped IMU
  // frame, as described in APN 065, page 6
  const imuToAxesSwappedImu = new Matrix3()
  switch (upAxis) {
    // clang-format off
    case UpAxis.XUp:
      imuToAxesSwappedImu.set(
        0, 0, 1,
        1, 0, 0,
        0, 1, 0,
      )
      break
    case UpAxis.XDown:
      imuToAxesSwappedImu.set(
        0, 0, -1,
        0, 1, 0,
        1, 0, 0,
      )
      break
    case UpAxis.YUp:
      imuToAxesSwappedImu.set(
        0, 1, 0,
        0, 0, 1,
        1, 0, 0,
      )
      break
    case UpAxis.YDown:
      imuToAxesSwappedImu.set(
        1, 0, 0,
        0, 0, -1,
        0, 1, 0,
      )
      break
    case UpAxis.ZUp:
      imuToAxesSwappedImu.set(
        1, 0, 0,
        0, 1, 0,
        0, 0, 1,
      )
      break
    case UpAxis.ZDown:
      imuToAxesSwappedImu.set(
        0, 1, 0,
        1, 0, 0,
        0, 0, -1,
      )
      break
    default:
      return imuToAxesSwappedImu.identity()
      // clang-format off
  }
  return imuToAxesSwappedImu
}

function setNovAtelOem7 (imuToVehicle) {
  if (!imuToVehicle || typeof imuToVehicle !== 'object') {
    return new Matrix3().identity()
  }
  // We seek the rotation from the PLS frame to the IMU frame
  const plsToNovatelVehicle = getPlsToNovAtelVehicle()
  // This is the input ZXY rotation, as described in APN 065, page 6
  const novatelImuToVehicle = makeZXYRotationMatrix(imuToVehicle)
  return plsToNovatelVehicle.multiply(novatelImuToVehicle.transpose())
}

function setNovAtelOem6 (o) {
  if (!o || typeof o !== 'object' || !o.vehicleBodyRotation || typeof o.vehicleBodyRotation !== 'object') {
    return new Matrix3().identity()
  }
  // We seek the rotation from the PLS frame to the IMU frame
  const plsToNovatelVehicle = getPlsToNovAtelVehicle()
  // This is the input ZXY rotation, as described in APN 065, page 6
  const novatelImuToVehicle = makeZXYRotationMatrix(o.vehicleBodyRotation)
  const imuToAxesSwappedImu = getNovAtelImuAxisRemapping(o.upAxis)
  plsToNovatelVehicle.multiply(novatelImuToVehicle.multiply(imuToAxesSwappedImu.transpose()))
  return plsToNovatelVehicle
}

function getNovAtelOem6 (vehicleBodyRotation, imuOrientation) {
  return {
    upAxis: imuOrientation,
    vehicleBodyRotation,
  }
}

function getNovAtelOem7 (vehicleBodyRotation, imuOrientation) {
  return vehicleBodyRotation
}
/*
function getPls (mRotationPlsToImu) {
  const euler = new Euler().setFromRotationMatrix(getRotationMatrixFromMatrix3(mRotationPlsToImu), 'YXZ')
  return {
    x: radiansToDegrees(euler.x),
    y: radiansToDegrees(euler.y),
    z: radiansToDegrees(euler.z),
  }
}*
*/

export function getNovAtelInertialExplorer (orientation, mRotationPlsToImuProvided) {
  // rotationPlsToImu = plsToNovatelVehicle * novatelImuToVehicle^-1
  // therefore
  // novatelImuToVehicle = rotationPlsToImu^-1 * plsToNovatelVehicle
  let mRotationPlsToImu = null
  if (mRotationPlsToImuProvided) {
    mRotationPlsToImu = mRotationPlsToImuProvided
  } else {
    // const mRotationEnuToImu = setRotationPlsToImu(makeZYXRotationMatrix(orientation))
    // mRotationPlsToImu = getRotationPlsToImu(mRotationEnuToImu)
    // v5-v7 use the same PLS-to-IMU rotation in ZXY/RPY order
    mRotationPlsToImu = fromYPR(new Vector3(), orientation.y, orientation.x, orientation.z).rotation
  }
  const plsToNovatelVehicle = getPlsToNovAtelVehicle()
  const novatelImuToVehicle = mRotationPlsToImu.transpose().multiply(plsToNovatelVehicle)

  // Oem7 is imu->vehicle in ZXY order, rotating axes
  // IE   is vehicle->imu in ZXY order, rotating axes
  const novatelVehicleToImu = novatelImuToVehicle.transpose()

  // Give us angles in ZXY order, around *rotating* axes.
  const euler = new Euler().setFromRotationMatrix(getRotationMatrixFromMatrix3(novatelVehicleToImu), 'ZXY')
  return {
    x: radiansToDegrees(euler.x),
    y: radiansToDegrees(euler.y),
    z: radiansToDegrees(euler.z),
  }
}
/*
function setRotationPlsToImu (rotationPlsToImu) {
  const m = getMatrix3FromArray([
    1, 0, 0,
    0, 0, -1,
    0, 1, 0,
  ])
  return m.multiply(rotationPlsToImu)
}

function getRotationPlsToImu (mRotationEnuToImu) {
  const m = getMatrix3FromArray([
    1, 0, 0,
    0, 0, 1,
    0, -1, 0,
  ])
  return m.multiply(mRotationEnuToImu)
}
*/
