import { token as getToken } from 'modules/users/selectors'
import { toast } from 'react-toastify'
import i18n from 'i18n'
import { convertRawArtifact } from 'types/artifacts'
import { convertRawDataDirectory } from 'types/dataDirectories'
import { convertRawDataFile } from 'types/dataFiles'
import { getAuthentificationHeader, getErrorMessage, showErrorMessage } from 'utils/api'
import axios from 'utils/axios'
// Local deps
import ArtifactsActions from './actions'
import UploadActions from '../upload/actions'
import { LidarmillSentry, isProductionEnvironment } from 'config'
import { getBrowser } from 'modules/app/selectors'
import { replaceMultipleWhiteSpaces } from 'utils/baseName'

function convertToBackendChunks (chunks) {
  return chunks.map(chunk => ({
    chunk_id: chunk.id,
    chunk_size: chunk.size,
    checksum: chunk.checksum,
  }))
}

export async function createArtifact (headers, projectId, name, artifactType, properties, missionId = undefined) {
  try {
    const url = `/projects/${projectId}/artifacts`
    const body = {
      name,
      artifact_type: artifactType,
      properties: properties || {},
      mission_id: missionId,
    }
    const result = await axios.post(url, body, { headers })
    const artifact = convertRawArtifact(result.data.data)
    return artifact
  } catch (e) {
    showErrorMessage(e)
    throw e
  }
}

export async function createDataDirectory (headers, artifactId) {
  try {
    const url = `/artifacts/${artifactId}/data_directories`
    const result = await axios.post(url, null, { headers })
    const { data } = result.data
    const dataDirectory = convertRawDataDirectory(data)
    return dataDirectory
  } catch (e) {
    showErrorMessage(e)
    throw e
  }
}

export async function getDataDirectory (dataDirectoryId) {
  const url = `/data_directories/${dataDirectoryId}`
  try {
    const result = await axios.get(url)
    const { data } = result.data
    const dataDirectory = convertRawDataDirectory(data)
    return dataDirectory
  } catch (e) {
  }
}

export async function listDataFiles (artifactId) {
  const url = `/artifacts/${artifactId}/data_files`
  try {
    const result = await axios.get(url)
    const { data } = result.data
    const dataFiles = data ? data.map(convertRawDataFile) : []
    return dataFiles
  } catch (e) {
    toast.error(i18n.t('toast.artifact.listDataFilesError'))
    return []
  }
}

/**
 * Retrieves the minimum GNSS velocity required for alignment from the server based on the artifact ID and dynamics model.
 * @param {string} artifactId - The unique identifier for the artifact.
 * @param {string} model - The dynamics model used for the query.
 * @param {string} token - Authorization token for authentication.
 * @returns {Promise<string>} A promise that resolves to the minimum GNSS velocity as a string or an empty string if not found.
 * @throws {Error} Throws an error if the request fails or if there is an issue with the network.
 */
export async function getMinimumAlignmentVelocity(artifactId, model) {
  try {
    const url = `/artifacts/${artifactId}/profiles_setings`
    const body = { dynamics_model: model }
    const { data: { data } } = await axios.post(url, body)
    return data.min_gnss_velocity || ''
  } catch (e) {
    return ''
  }
}

/**
 * Retrieves a download token for a given artifact
 * @param {String} artifactId
 * @returns {String} download token
 */
export async function getArtifactDownloadToken (artifactId) {
  try {
    const { data: { data: { token } } } = await axios.get(`/artifacts/${artifactId}/download_token`)
    return token
  } catch (e) {
    showErrorMessage(e)
  }
}

export function logDataFileAction (token, browser) {
  if (!isProductionEnvironment()) {
    const headers = getAuthentificationHeader(token)
    return data => {
      const transformedData = {
        artifact_id: data.artifactId,
        data_file_id: data.dataFileId,
        chunk_id: data.chunkId,
        file_name: data.fileName,
        size: data.size,
        duration: data.duration,
        message: data.message,
        status: data.status,
        action: data.action,
        rate: data.rate,
        browser,
      }
      axios.post('/data_files/logging', transformedData, { headers })
    }
  }
  return () => {}
}

export function logDataDirectoryAction (token, browser) {
  if (!isProductionEnvironment()) {
    const headers = getAuthentificationHeader(token)
    return data => {
      const transformedData = {
        artifact_id: data.artifactId,
        data_directory_id: data.dataDirectoryId,
        file_name: data.fileName,
        size: data.size,
        duration: data.duration,
        message: data.message,
        status: data.status,
        action: data.action,
        rate: data.rate,
        browser,
      }
      axios.post('/data_directories/logging', transformedData, { headers })
    }
  }
  return () => {}
}

export default function (store) {
  const state = store.getState()
  const dispatch = store.dispatch
  const token = getToken(state)
  const headers = getAuthentificationHeader(token)
  const browser = getBrowser(state)

  const logDataFile = logDataFileAction(token, browser)
  const logDataDirectory = logDataDirectoryAction(token, browser)

  /**
   * Sets the `completed` status of a data directory to `true`.
   * @param dataDirectoryId The id of the data directory that should be modified.
   */
  async function completeDataDirectory (dataDirectoryId) {
    const body = { completed: true }
    return axios.post(`/data_directories/${dataDirectoryId}`, body, { headers })
      .catch(e => console.error('Error occured while setting data directory \'completed\' to true'))
  }

  /**
   * Sets the `completed` status of an artifact to `true`.
   * @param artifactId The id of the artifact that should be modified.
   * @param baseUrl The base Url of the backend to communicate with.
   * @param authToken The authentication token of the user.
   */
  async function completeArtifact (artifactId) {
    dispatch(ArtifactsActions.putCompleteArtifactLoading(artifactId))
    const body = { completed: true }
    return axios.post(`/artifacts/${artifactId}`, body, { headers })
      .then(result => {
        // dispatch(ArtifactsActions.putCompleteArtifact(artifact))
      })
      .catch(e => {
        console.error('Error occured while setting artifact \'completed\' to true')
        // dispatch(ArtifactsActions.putCompleteArtifactError(artifact))
      })
  }

  /**
   * Get the artifact.
   * @param artifactId The id of the artifact.
   * @param baseUrl The base Url of the backend to communicate with.
   * @param authToken The authentication token of the user.
   */
  async function getArtifact (artifactId) {
    dispatch(ArtifactsActions.putGetArtifactLoading(artifactId))
    try {
      const { data: { data: result } } = await axios.get(`/artifacts/${artifactId}`, { headers })
      const artifact = convertRawArtifact(result)

      dispatch(ArtifactsActions.putGetArtifact(artifact))

      return artifact
    } catch (e) {
      dispatch(ArtifactsActions.putGetArtifactError(artifactId, e.message))
      showErrorMessage(e)
    }
  }

  async function createArtifact (projectId, name, artifactType, properties) {
    // dispatch(ArtifactsActions.putCreateArtifactLoading())

    /*
    const url = `/projects/${projectId}/artifacts`
    const body = {
      name,
      artifact_type: artifactType,
      properties: properties || {},
    }
    */

    try {
      // const result = await axios.post(url, body, { headers })
      // const artifact = convertRawArtifact(result.data.data)

      const artifact = await createArtifact(headers, projectId, name, artifactType, properties)
      dispatch(ArtifactsActions.putCreateArtifact(artifact))

      return artifact
    } catch (e) {
      dispatch(ArtifactsActions.putCreateArtifactError(e.message))
      showErrorMessage(e)
    }
  }

  async function createDataDirectory (artifactId) {
    dispatch(ArtifactsActions.putCreateDataDirectoryLoading(artifactId))
    try {
      const result = await axios.post(`/artifacts/${artifactId}/data_directories`, null, { headers })
      const { data } = result.data
      const dataDirectory = convertRawDataDirectory(data)

      dispatch(ArtifactsActions.putCreateDataDirectory(dataDirectory))

      return dataDirectory
    } catch (e) {
      dispatch(ArtifactsActions.putCreateDataDirectoryError(artifactId))
      showErrorMessage(e)
    }
  }

  async function getDataDirectory (dataDirectoryId) {
    dispatch(ArtifactsActions.putGetDataDirectoryLoading(dataDirectoryId))
    try {
      const result = await axios.get(`/data_directories/${dataDirectoryId}`, { headers })
      const { data } = result.data
      const dataDirectory = convertRawDataDirectory(data)

      dispatch(ArtifactsActions.putGetDataDirectory(dataDirectory))

      return dataDirectory
    } catch (e) {
      dispatch(ArtifactsActions.putGetDataDirectoryError(dataDirectoryId, e.message))
      showErrorMessage(e)
      // throw e
    }
  }

  async function listDataFiles (artifactId) {
    dispatch(ArtifactsActions.putListDataFilesLoading(artifactId))
    try {
      const result = await axios.get(`/artifacts/${artifactId}/data_files`, { headers })
      const { data } = result.data
      const dataFiles = data ? data.map(convertRawDataFile) : []

      dispatch(ArtifactsActions.putListDataFiles(dataFiles, artifactId))

      return { artifactId, dataFiles }
    } catch (e) {
      dispatch(ArtifactsActions.putListDataFilesError(artifactId, e.message))
      // throw e
    }
  }

  async function updateDataDirectory (dataDirectoryId, s3KeyPrefix, file, data, replaceImageName) {
    try {
      const prefix = s3KeyPrefix ? `${s3KeyPrefix}/` : ''
      const body = {
        data,
        s3_key: `${prefix}${file.name}`,
        ...(typeof replaceImageName === 'string' ? { replace: `${prefix}${replaceImageName}` } : {}),
      }
      return axios.put(`/data_directories/${dataDirectoryId}`, JSON.stringify(body), { headers })
    } catch (e) {
      showErrorMessage(e)
      console.error(`Error occured while updateDataDirectory. dataDirectoryId=${dataDirectoryId}`)
      console.error(e)
      throw e
    }
  }

  async function updateDataDirectoryDescription (artifactId, dataDirectoryId, description) {
    try {
      const url = `/data_directories/${dataDirectoryId}`
      const body = {
        description,
      }
      return axios.post(url, JSON.stringify(body), { headers })
    } catch (e) {
      console.error(`Error occured while updateDataDirectoryDescription. dataDirectoryId=${dataDirectoryId}`)
      console.error(e)
      logDataDirectory({
        level: 'error',
        action: 'UPDATE/ERROR',
        artifactId: artifactId,
        dataDirectoryId: dataDirectoryId,
        message: 'Status of the data directory was not updated. ' + getErrorMessage(e),
      })
    }
  }

  async function updateDataDirectorySize (artifactId, dataDirectoryId, newSize) {
    try {
      const url = `/data_directories/${dataDirectoryId}`
      const { data: { data: dataDirectory } } = await axios.get(url, { headers })
      const body = {
        size: dataDirectory.size + newSize,
      }
      return axios.post(url, JSON.stringify(body), { headers })
    } catch (e) {
      console.error(`Error occured while updateDataDirectorySize. dataDirectoryId=${dataDirectoryId}`)
      console.error(e)
      logDataDirectory({
        level: 'error',
        action: 'UPDATE/ERROR',
        artifactId: artifactId,
        dataDirectoryId: dataDirectoryId,
        size: newSize,
        message: 'Size of the data directory was not updated. ' + getErrorMessage(e),
      })
    }
  }

  async function createDataFile (artifactId, filename, chunks, checkSum) {
    try {
      const url = `/artifacts/${artifactId}/data_files`
      const body = {
        file_name: replaceMultipleWhiteSpaces(filename),
        chunks: convertToBackendChunks(chunks),
        ...(checkSum && { sha1_checksum: checkSum }),
      }
      const result = await axios.post(url, body, { headers })
      const { data } = result.data
      return convertRawDataFile(data)
    } catch (e) {
      showErrorMessage(e)
      console.error(`Error occured while createDataFile. artifactId=${artifactId},filename=${filename}`)
      console.error(chunks)
      console.error(e)
      logDataFile({
        level: 'error',
        action: 'CREATE/ERROR',
        artifactId: artifactId,
        fileName: filename,
        message: 'Error occured while creating data file. ' + getErrorMessage(e),
      })
    }
  }

  async function createDataFiles (artifactId, files) {
    try {
      const url = `/artifacts/${artifactId}/data_files/batch`
      const body = files.map(file => ({ file_name: replaceMultipleWhiteSpaces(file.file.name), chunks: convertToBackendChunks([]) }))
      const result = await axios.post(url, body, { headers })
      const { data } = result.data
      return data.map(dataFile => convertRawDataFile(dataFile))
    } catch (e) {
      showErrorMessage(e)
      logDataFile({
        level: 'error',
        action: 'CREATE/DATA_FILES',
        artifactId: artifactId,
        fileName: files.map(file => file.file.name).join(', '),
        message: 'Error occured while creating data files. ' + getErrorMessage(e),
      })
    }
  }

  async function updateArtifactProperties (artifactId, properties) {
    try {
      const url = `/artifacts/${artifactId}`
      const body = { properties }
      const result = await axios.post(url, body, { headers })
      const { data } = result.data
      return convertRawArtifact(data)
    } catch (e) {
      showErrorMessage(e)
      console.error(`Error occured while updateArtifactProperties. artifactId=${artifactId}`)
      console.error(properties)
      console.error(e)
    }
  }

  async function updateDataFile (artifactId, dataFileId, { description, chunks, checkSum }) {
    try {
      const url = `/data_files/${dataFileId}`
      const body = {
        ...(description && { description }),
        ...(chunks && { chunks: convertToBackendChunks(chunks) }),
        ...(checkSum && { sha1_checksum: checkSum }),
      }
      const result = await axios.post(url, body, { headers })
      const { data } = result.data
      return convertRawDataFile(data)
    } catch (e) {
      showErrorMessage(e)
      console.error(`Error occured while updateDataFile. dataFileId=${dataFileId}`)
      console.error(e)
      logDataFile({
        level: 'error',
        action: 'UPDATE/ERROR',
        artifactId: artifactId,
        dataFileId: dataFileId,
        message: 'Chunks of the data file were not updated. ' + getErrorMessage(e),
      })
    }
  }

  async function updateDataFileDescription (artifactId, dataFileId, description) {
    try {
      const body = {
        description,
      }
      return axios.post(`/data_files/${dataFileId}`, JSON.stringify(body), { headers })
    } catch (e) {
      showErrorMessage(e)
      logDataFile({
        level: 'error',
        action: 'UPDATE/ERROR',
        artifactId: artifactId,
        dataFileId: dataFileId,
        message: 'Status of the data file was not updated. ' + getErrorMessage(e),
      })
      console.error(`Error occured while updateDataFile description. dataFileId=${dataFileId}`)
      console.error(e)
    }
  }

  async function getDataFile (dataFileId) {
    try {
      const url = `/data_files/${dataFileId}`
      const result = await axios.get(url, { headers })
      const { data } = result.data
      return convertRawDataFile(data)
    } catch (e) {
      showErrorMessage(e)
      // throw e
    }
  }

  async function getPresignedUrls (artifactId, dataFileId) {
    try {
      const url = `/data_files/${dataFileId}/pre_signed_urls`
      const result = await axios.get(url, { headers })
      const { data } = result.data
      return data
    } catch (e) {
      const errorMessage = getErrorMessage(e, 'Server error')
      logDataFile({
        level: 'error',
        action: 'PRESIGNED_URL/GET/ERROR',
        artifactId,
        dataFileId,
        message: 'Can\'t get presigned urls for a data file. ' + errorMessage,
      })
      showErrorMessage(e)
      // throw e
    }
  }

  async function completeDataFile (artifactId, dataFileId) {
    try {
      const url = `/data_files/${dataFileId}/complete_multipart_upload`
      const result = await axios.post(url, {}, { headers })
      const { data } = result.data
      logDataFile({
        level: 'info',
        action: 'COMPLETE/DONE',
        artifactId,
        dataFileId,
        message: 'Data file was successfully completed.',
      })
      const dataFile = convertRawDataFile(data)
      dispatch(UploadActions.dataFileDone(dataFileId, dataFile))
      return dataFile
    } catch (e) {
      showErrorMessage(e)
      logDataFile({
        level: 'error',
        action: 'COMPLETE/ERROR',
        artifactId,
        dataFileId,
        message: 'Error while completing data file. ' + getErrorMessage(e),
      })
      throw new Error()
      // throw e
    }
  }

  async function completeChunk (artifactId, dataFileId, chunkId) {
    try {
      const url = `/data_files/${dataFileId}/chunks/${chunkId}/complete`
      const result = await axios.post(url, {}, { headers })
      logDataFile({
        level: 'info',
        action: 'CHUNK/COMPLETE/DONE',
        artifactId,
        dataFileId,
        chunkId,
        message: 'Chunk was successfully completed. ',
      })
      dispatch(UploadActions.dataFileChunkDone(dataFileId, chunkId))
      return result
    } catch (e) {
      logDataFile({
        level: 'error',
        action: 'CHUNK/COMPLETE/ERROR',
        artifactId,
        dataFileId,
        chunkId,
        message: 'Error while completing chunk. ' + getErrorMessage(e),
      })
      showErrorMessage(e)
      throw new Error()
      // throw e
    }
  }

  async function getDataDirectoryFilePresignedUrl (artifactId, dataDirectoryId, s3KeyPrefix, file) {
    const fileName = file && file.name
    try {
      const prefix = s3KeyPrefix ? `${s3KeyPrefix}/` : ''
      const url = `/data_directories/${dataDirectoryId}/pre_signed_urls?s3_key=${prefix}${fileName}`
      const result = await axios.get(url, { headers })
      const { data: { data } } = result
      return { okay: true, data }
    } catch (e) {
      showErrorMessage(e)
      logDataDirectory({
        level: 'error',
        action: 'PRESIGNED_URL/GET/ERROR',
        artifactId,
        dataDirectoryId,
        fileName,
        message: 'Can\'t get presigned url for a file ' +
          '(' + s3KeyPrefix + ', ' + fileName + '). ' +
          getErrorMessage(e) + ';' +
          e.toString(),
      })
      return { okay: false }
      // throw e
    }
  }

  return {
    getArtifact,
    createArtifact,
    completeArtifact,
    getDataDirectory,
    completeDataDirectory,
    listDataFiles,
    updateDataDirectory,
    updateDataDirectoryDescription,
    createDataDirectory,
    createDataFile,
    createDataFiles,
    updateDataFile,
    updateDataFileDescription,
    updateArtifactProperties,
    getDataFile,
    getPresignedUrls,
    completeDataFile,
    completeChunk,
    getDataDirectoryFilePresignedUrl,
    updateDataDirectorySize,
    logDataFile,
    logDataDirectory,
  }
}
