import moment from 'utils/moment'
import moment_ from 'moment'
import { withMaxPrecision } from 'utils/numeric'
import { GpsStandardTime } from 'utils/time'
import config from 'config'

export const getUTC = date => {
  if (date) {
    return moment_.utc(date).utcOffset('+00:00')
  }
  return moment_.utc().utcOffset('+00:00')
}

export const getUTCDate = date => {
  return moment_.utc(date).utcOffset('+00:00').toDate()
}

export const getDateWithTimezone = date => {
  return moment(getUTC(date).toDate())
}

/**
 * Returns the prettified version (..h..m..s..ms) of the time based on milliseconds
 * @param {number} milliseconds
 */
export function prettifyTime (milliseconds, withNull = true) {
  const duration = moment.duration(milliseconds, 'milliseconds')
  const hours = duration.days() > 0 ? (duration.days() * 24) + duration.hours() : duration.hours()
  const durationInSeconds = duration.seconds()
  const durationInMinutes = duration.minutes()
  const displayHours = withNull || hours > 0
  const displayMinutes = withNull || durationInMinutes > 0 || (hours > 0 && durationInSeconds > 0)
  const displaySeconds = withNull || durationInSeconds > 0
  const displayMilliseconds = durationInSeconds === 0 && durationInMinutes === 0 && hours === 0
    ? duration.milliseconds()
    : null
  return [
    displayHours ? `${hours}h` : '',
    displayMinutes ? `${durationInMinutes}m` : '',
    displaySeconds ? `${durationInSeconds}s` : '',
    displayMilliseconds ? `${displayMilliseconds}ms` : '',
  ].filter(Boolean).join(' ')
  // return `${!withNull ? hours > 0 ? hours + 'h ' : '' : `${hours}h`}${!withNull ? durationInMinutes > 0 ? durationInMinutes + 'm ' : '' : `${durationInMinutes}m`}${!withNull ? durationInSeconds > 0 ? durationInSeconds + 's' : '' : `${durationInSeconds}s`}${displayMilliseconds ? `${displayMilliseconds}ms` : ''}`
}

/**
 * Returns the prettified version (..h..m..s..ms) of the time based on milliseconds
 * @param {number} milliseconds
 */
export function prettifyTime1 (milliseconds, withNull = true) {
  const durationInSeconds = milliseconds / 1000
  const res = []
  if (durationInSeconds > 60) {
    const minutes = Math.floor(durationInSeconds / 60)
    const remainedSeconds = durationInSeconds - minutes * 60
    res.push(`${minutes}m`)
    res.push(`${withMaxPrecision(remainedSeconds.toString(), 2)}s`)
  }
  if (durationInSeconds > 0 && durationInSeconds < 60) {
    res.push(`${withMaxPrecision(durationInSeconds.toString(), 2)}s`)
  }
  if (durationInSeconds < 1) {
    res.push(`${durationInSeconds * 1000}ms`)
  }
  if (durationInSeconds <= 0) {
    res.push('0ms')
  }
  return res.join(' ')
}

/**
 * Returns the number of weeks between two dates
 * @param {*} d2
 * @param {*} d1
 */
export const getNumberOfWeeks = (d2, d1 = GpsStandardTime.clone()) => {
  const format = 'DD/MM/YYYY'
  if (d2 === '') return 0
  const momentD1 = moment.utc(d1, format)
  const momentD2 = moment.utc(d2, format)
  if (momentD1.isValid() && momentD2.isValid()) {
    const diff = moment.duration(momentD2.diff(momentD1))
    return Math.floor(diff.asWeeks())
  }
  return 0
}

export function getScheduledTime (time) {
  return moment(time * 1000)
    .utcOffset('+00:00')
    .format(config.DATETIME_FORMAT)
}

/**
 * Check if year is a leap year
 * @param {number} year
 */
const isLeapYear = year => {
  return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0)
}

const getDaysInYear = year => {
  return isLeapYear(year) ? 366 : 365
}

/**
 * Transform date to decimal year
 * @param {Object|string} date
 */
export const toDecimalYear = (date, precision = 3) => {
  if (date) {
    const momentDate = moment(date)
    const year = momentDate.year()
    const day = momentDate.dayOfYear() - 1
    const daysPerYear = getDaysInYear(year)
    return withMaxPrecision((year + (day / daysPerYear)).toString(), precision)
  }
  return null
}

/**
 * Transform decimal year back to Date object
 * @param {string} decimalDate
 */
export const fromDecimalYear = decimalDate => {
  const year = parseInt(decimalDate)
  const reminder = decimalDate - year
  const daysPerYear = getDaysInYear(year)
  const dayOfYear = reminder * daysPerYear + 1
  return moment().year(year).dayOfYear(dayOfYear <= 0 ? 1 : dayOfYear).toDate()
}
