import { createSelector } from 'reselect'
import { DataType } from 'types/form'
import { getCoordinateSystems } from 'modules/app/selectors'
import { isGCPArtifact } from 'types/artifacts'
const coordinateSystemsSelector = state => getCoordinateSystems(state)

export const TEMPLATE_POSITION = {
  START: 0,
  END: 1,
}

// Building the list of suggestions is a really expensive operation on a huge set of data which is
// why the result has to be cached using reselect.
export const coordinateSystemsSuggestionsSelector =
  createSelector(coordinateSystemsSelector, coordinateSystems =>
    coordinateSystems.map(coordinateSystem => coordinateSystem.description),
  )

export function isStringFormField (dataType) {
  return dataType === DataType.STRING
}

export function isBooleanFormField (dataType) {
  return dataType === DataType.BOOLEAN
}

export function isIntegerFormField (dataType) {
  return dataType === DataType.INTEGER
}

export function isFloatFormField (dataType) {
  return dataType === DataType.FLOAT
}

export function isMultiFloatFormField (dataType) {
  return dataType === DataType.MULTI_FLOAT
}

export function isSelectionFormField (dataType) {
  return dataType === DataType.SELECTION
}

export function isMultiSelectionFormField (dataType) {
  return dataType === DataType.MULTI_SELECTION
}

export function isAutocompleteFormField (dataType) {
  return dataType === DataType.AUTOCOMPLETE
}

export function isGeoidAutocompleteFormField (dataType) {
  return dataType === DataType.GEOID_AUTOCOMPLETE
}

export function isVector3FormField (dataType) {
  return dataType === DataType.VECTOR3
}

export function isDatetimeFormField (dataType) {
  return dataType === DataType.DATETIME
}

export function isCoordinateFormField (dataType) {
  return dataType === DataType.COORDINATE
}

export function isCoordinateSystemsFormField (dataType) {
  return dataType === DataType.COORDINATE_SYSTEMS
}

export function isTabsFormField (dataType) {
  return dataType === DataType.TABS
}

export function isCesiumMapFormField (dataType) {
  return dataType === DataType.CESIUM_MAP
}

export function isCustomCRSFormField (dataType) {
  return dataType === DataType.CUSTOM_CRS
}

export function isGPSWeekFormField (dataType) {
  return dataType === DataType.GPS_WEEK
}

/**
* Clean this form value.
* Tries to apply the mapping, if present. Afterwards converts the values to the correct types.
* TODO: Error handling.
* @param formField The form field definition.
* @param value The value that the user supplied.
* @param formValues All values from the form. Will be passed to the function for generating the mapping if specified.
* @param state The application's state.
* @param extra Extra parameters handed down to lambdas.
* @return A resolved and correctly typed value.
*/
function cleanTemplatedFormValues (
  formField,
  value,
  formValues,
  state,
  extra) {
  const { mapping, dataType } = formField
  // It is possible to specify the mapping for the values as
  // * a map or
  // * to supply a function  which will either
  //     * return the mapping or
  //     * the value directly.
  if (mapping) {
    let mappedValue
    // If the mapping is a function, it needs to be evaluated:
    if (typeof mapping === 'function') {
      const createdMapping = mapping(state, formValues, extra)
      // If the result is an object, it will be treated as a map to look up the desired
      // value in ...
      if (typeof createdMapping === 'object' && !Array.isArray(createdMapping)) {
        mappedValue = createdMapping[value]
      } else {
        // ... otherwise the returned value will be used.
        mappedValue = createdMapping
      }
    } else {
      // If the mapping was not a function it is considered a map to look up the value in.
      mappedValue = mapping[value]
    }
    // If the field has a mapping applied no more conversions should be executed.
    if (typeof mappedValue !== 'undefined') {
      return mappedValue
    }
  }
  // If the field has a transform applied no more conversions should be executed.
  if (typeof formField.transform === 'function') {
    return value
  }
  if (isStringFormField(dataType)) {
    return `${value}`
  }
  if (isFloatFormField(dataType)) {
    return parseFloat(value)
  }
  if (isIntegerFormField(dataType)) {
    return parseInt(value, 10)
  }
  if (isBooleanFormField(dataType)) {
    return Boolean(value)
  }
  if (isSelectionFormField(dataType) || isMultiSelectionFormField(dataType)) {
    return value
  }
  if (isAutocompleteFormField(dataType) || isGeoidAutocompleteFormField(dataType) || dataType === DataType.ANTENNA_AUTOCOMPLETE) {
    return value
  }
  if (isVector3FormField(dataType)) {
    if (Array.isArray(value) && value.length === 3 && typeof value[0] === 'string') {
      return value.map(component => parseFloat(component))
    }
    return value
  }
  if (
    dataType === DataType.TABS ||
    dataType === DataType.TABS_TEMPLATE ||
    isCustomCRSFormField(dataType) ||
    dataType === DataType.LAST_CHANGED_TAB ||
    isGPSWeekFormField(dataType) ||
    dataType === DataType.TIME_OF_WEEK ||
    dataType === DataType.CRS ||
    dataType === DataType.TEXT ||
    dataType === DataType.SLIDER ||
    dataType === DataType.TRAJECTORY_AUTOSPLIT
  ) {
    return value
  }
  // For multi-float field no more transform required
  if (isMultiFloatFormField(dataType)) {
    return value
  }
}

/**
* Given a mapping of values, cleans and returns the values.
* @param formTemplate The form definitions.
* @param values The value definitions.
* @param state The application's state.
* @param extra Extra parameters handed down to lambdas.
* @return The result of cleaning the values.
*/
export function cleanJobOptionValues (
  formTemplate,
  values,
  state,
  extra,
) {
  return Object.keys(formTemplate).reduce(
    (result, name) => {
      const formField = formTemplate[name]
      const value = cleanTemplatedFormValues(formField, values[name], values, state, extra)
      if (typeof value !== 'undefined') {
        return {
          ...result,
          [name]: value,
        }
      }
      return result
    },
    {})
}

export const getNumberOfSelectedTemplateArtifacts = (pipelineTemplate, selectedTemplateArtifacts) => {
  const gcpArtifact = pipelineTemplate.artifacts.find(artifact => isGCPArtifact(artifact.artifactType))
  const gcpArtifactId = gcpArtifact && gcpArtifact.templateId
  const artifacts = selectedTemplateArtifacts[gcpArtifactId]
  return artifacts ? artifacts.length : 0
}

export const isTemplateContainGCP = (pipelineTemplate, selectedTemplateArtifacts) => {
  return getNumberOfSelectedTemplateArtifacts(pipelineTemplate, selectedTemplateArtifacts) > 0
}
