import React from 'react'
import i18n from 'i18n'
// Material UI
import { Typography } from '@material-ui/core'
// Project deps
import { JobType } from 'types/jobs'
import { prettifyArtifactType } from 'utils/artifacts'
import {
  ArtifactTypes,
  isRefStationArtifact,
  isNavRoverArtifact,
  isNavRoverApplanixArtifact,
  isGeotiffArtifact,
} from 'types/artifacts'
import { TEMPLATE_POSITION } from 'utils/jobOptions'
import { getSelectedReferenceStationArtifacts, getPipelineTemplate, getCloneJobOptions } from 'modules/pipelineWizard/selectors'
import { findById } from 'utils/list'
import { isNavLabJob, prettifyJobType } from 'utils/jobs'
import { isTrajectoryArtifactHasSameIMUOrientation, getTrajectoryArtifacts, getCompanyCalibrationsForCurrentProject } from './utils'
import WikiButton from 'components/WikiButton'
import { Wiki } from 'wiki'
import { getCurrentProjectCRSId } from 'modules/projects/selectors'
import { PipelineWizardType } from 'types/pipelines'
import { isAdmin, isCRSAdvanced } from 'modules/users/selectors'
import { isComputeGNSSAntennaOptionSelected } from './jobOptionsUtils'
import { isProductionEnvironment } from 'config'
// import { isProductionEnvironment } from "./utils/environment";

/**
 * Show CRS step only when:
 * 1) User is admin or
 * 2) User has not defined CRS for a project or
 * 3) User is advanced CRS user
 * @param {*} state
 */
const displayOutputCRSStep = state => {
  const projectCRSId = getCurrentProjectCRSId(state)
  const isCRSAdvancedUser = isCRSAdvanced(state)
  return isAdmin(state) || !projectCRSId || Boolean(isCRSAdvancedUser)
}

/**
 * The pipeline template for a single `NavLab` `Job`.
 */
export const navlabTemplate1 = {
  type: PipelineWizardType.NAVLAB,
  name: i18n.t('templates.jobs.navlab'),
  visible: artifacts => {
    return artifacts.some(({ artifactType }) => isNavRoverArtifact(artifactType)) // || isReconDataArtifact(artifactType))
  },
  artifacts: [
    {
      templateId: 0,
      artifactType: ArtifactTypes.NAVIGATION_ROVER,
      // Indicate to create multiple pipelines
      // Create as much pipelines as artifacts with templateId were selected
      // Every new pipeline has one artifact of templateId type
      // other artifacts just added to the selected one
      multiplePipelines: true,
    },
    /*
    {
      templateId: 1,
      artifactType: ArtifactTypes.RECON_DATA,
      // Indicate to create multiple pipelines
      // Create as much pipelines as artifacts with templateId were selected
      // Every new pipeline has one artifact of templateId type
      // other artifacts just added to the selected one
      multiplePipelines: true,
      split: true,
      splitBy: [ArtifactTypes.NAVIGATION_ROVER],
      description: 'Can be used instead of Navigation Rover artifact',
    },
    */
    {
      templateId: 1,
      artifactType: ArtifactTypes.REFERENCE_STATION,
      // Indicate where all the properties will be written, null|undefined - into root properties
      // fieldName: 'coordinate_reference_systems',
    },
    {
      templateId: 2,
      artifactType: ArtifactTypes.TRAJECTORY,
      getProperties: (state, jobOptions, artifactOptions) => {
        const pipelineTemplate = getPipelineTemplate(state)
        const selectedReferenceStationArtifacts = getSelectedReferenceStationArtifacts(state)
        const { jobs } = pipelineTemplate
        const properties = {
          trajectory_datum: null,
          epoch: null,
        }
        const refStationId = Object.keys(artifactOptions).find(id => {
          const artifact = findById(id, selectedReferenceStationArtifacts)
          const options = artifactOptions[id] || {}
          const { values = {} } = options
          return (artifact && isRefStationArtifact(artifact.artifactType)) && Boolean(values.epoch)
        })
        if (refStationId) {
          const options = artifactOptions[refStationId] || {}
          properties.epoch = options.values.epoch || null
        }
        const navLabJob = jobs.find(job => isNavLabJob(job.jobType))
        if (navLabJob) {
          const options = jobOptions[navLabJob.templateId] || {}
          const { rawValues = {} } = options
          properties.trajectory_datum = rawValues.output_datum || null
        }
        return properties
      },
      getArtifactName: artifacts => {
        const navRoverArtifact = artifacts.find(art => isNavRoverArtifact(art.artifactType))
        const trajectoryName = prettifyArtifactType(ArtifactTypes.TRAJECTORY)
        return navRoverArtifact
          ? i18n.t('templates.artifactName.postProcessedFrom', { trajectoryName, navRoverName: navRoverArtifact.name })
          : i18n.t('templates.artifactName.postProcessed', { trajectoryName })
      },
    },
  ],
  jobs: [{
    templateId: 0,
    jobType: JobType.NAVLAB,
    position: TEMPLATE_POSITION.END,
    display: state => {
      if (isAdmin(state)) {
        return true
      }
      return !isComputeGNSSAntennaOptionSelected(state)
    },
    inputs: [
      {
        templateId: 0,
        min: 1, // artifacts => artifacts.some(artifact => isReconDataArtifact(artifact.artifactType)) ? 0 : 1,
        max: 100,
      },
      /*
      {
        templateId: 1,
        min: artifacts => artifacts.some(artifact => isNavRoverArtifact(artifact.artifactType)) ? 0 : 1,
        max: 100,
      },
      */
      { templateId: 1, min: 0, max: 100 },
    ],
    outputs: [
      { templateId: 2, min: 1, max: 100 },
    ],
  }],
}

export const navlabApplanixTemplate1 = {
  type: PipelineWizardType.NAVLAB_APPLANIX,
  name: i18n.t('templates.jobs.navlab_applanix'),
  visible: artifacts => {
    return artifacts.some(({ artifactType }) => isNavRoverApplanixArtifact(artifactType))
  },
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.NAVIGATION_ROVER_APPLANIX },
    { templateId: 1, artifactType: ArtifactTypes.REFERENCE_STATION },
    { templateId: 2, artifactType: ArtifactTypes.TRAJECTORY },
  ],
  jobs: [{
    templateId: 0,
    jobType: JobType.NAVLAB_APPLANIX,
    position: TEMPLATE_POSITION.END,
    inputs: [
      { templateId: 0, min: 1, max: 1 },
      { templateId: 1, min: 0, max: 100 },
    ],
    outputs: [
      { templateId: 2, min: 1, max: 1 },
    ],
  }],
}

/**
 * The pipeline template for a single `SpatialFuser` `Job`.
 */
export const spatialFuserTemplate1 = {
  type: PipelineWizardType.SPATIAL_FUSER,
  name: i18n.t('templates.jobs.spatial_fuser'),
  pipelineNameInConfigureStep: true,
  pipelineSettingsMergeJobTemplateId: 0,
  pipelineNameTemplateId: 100,
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.TRAJECTORY, useMissionLimits: true, useNonMissionArtifacts: false },
    { templateId: 1, artifactType: ArtifactTypes.LIDAR_DATA, group: true },
    { templateId: 2, artifactType: ArtifactTypes.CAMERA_DATA, group: true },
    { templateId: 3, artifactType: ArtifactTypes.GROUND_CONTROL_POINTS },
    {
      templateId: 4,
      artifactType: ArtifactTypes.POINTCLOUD,
      getArtifactName: (artifacts, pipeline) => {
        return i18n.t('templates.artifactName.pointcloudOutput', { pipelineName: pipeline.name })
      },
    },
    {
      templateId: 5,
      artifactType: ArtifactTypes.POTREE,
      shouldBeCreated: (state, jobOptions, artifactOptions, templateJobs) => {
        return jobOptions[0].values.products.create_pointcloud_viewer
      },
    },
    { templateId: 6, artifactType: ArtifactTypes.POLYGON },
    { templateId: 7, artifactType: ArtifactTypes.GEOTIFF },
    /*
    {
      templateId: 6,
      artifactType: ArtifactTypes.RECON_DATA,
      description: 'Can be used instead of LiDAR or/and Camera artifact',
      group: true,
      getGroupIndexes: (state, artifact) => {
        const missions = getMissions(state)
        const mission = findById(artifact.missionId, missions)
        if (mission && mission.plp) {
          return getSensorIndexesFromPlp(mission.plp, [
            {
              name: ArtifactTypes.CAMERA_DATA,
              paths: [['settingsRover', 'cameras'], ['sessionsCameras']],
            },
            {
              name: ArtifactTypes.LIDAR_DATA,
              paths: [['settingsRover', 'lidars'], ['sessionsLidars']],
            },
          ])
        }
        return { [ArtifactTypes.CAMERA_DATA]: [], [ArtifactTypes.LIDAR_DATA]: [] }
      },
      split: true,
      splitBy: [
        ArtifactTypes.LIDAR_DATA,
        ArtifactTypes.CAMERA_DATA,
      ],
    },
    */
    /*
    {
      templateId: 5,
      artifactType: ArtifactTypes.TILES,
      // Display the artifact on the first step in Pipeline wizard
      display: false,
      shouldBeCreated: (state, jobOptions, artifactOptions, templateJobs) => {
        return jobOptions[0].values.products.create_tiles
      },
    },
    */
  ],
  jobs: [
    {
      templateId: 0,
      jobType: JobType.SPATIAL_FUSER,
      onArtifactSelect: (artifactId, artifact, job, props) => {
        const {
          jobOptions,
          selectedCameraArtifacts,
          selectedGCPArtifacts,
          onJobOptionChange,
        } = props

        const id = job.templateId
        const options = jobOptions[id] || {}
        const { values } = options

        if (artifact.artifactType === ArtifactTypes.GROUND_CONTROL_POINTS) {
          if (selectedGCPArtifacts.length <= 0 && values) {
            onJobOptionChange(id, { aligntogcp: true }, true, { aligntogcp: true })
          }
        }
        if (artifact.artifactType === ArtifactTypes.CAMERA_DATA) {
          if (selectedCameraArtifacts.length <= 0 && values) {
            onJobOptionChange(id, { calibrateCamera: true }, true, { calibrateCamera: true })
          }
        }
      },
      inputs: [
        {
          templateId: 0,
          min: 1,
          max: 100,
          // Allow to select only one artifact per mission
          mission: {
            min: 1,
            max: 1,
          },
        },
        {
          templateId: 1,
          min: 1, // artifacts => artifacts.some(artifact => isReconDataArtifact(artifact.artifactType)) ? 0 : 1,
          max: 100,
        },
        {
          templateId: 2,
          min: 0,
          max: (artifacts) => artifacts.some(artifact => isGeotiffArtifact(artifact.artifactType)) ? 0 : 100
        },
        {
          templateId: 3,
          min: 0,
          max: 100,
        },
        {
          templateId: 6,
          min: 0,
          max: 1,
        },
        {
          templateId: 7,
          min: 0,
          max: 1,
        },
        /*
        {
          templateId: 6,
          min: artifacts => artifacts.some(artifact => isLidarArtifact(artifact.artifactType)) ? 0 : 1,
          max: 100,
        },
        */
      ],
      outputs: [
        { templateId: 4, min: 1, max: 1 },
        { templateId: 5, min: 1, max: 1 },
        // { templateId: 5, min: 0, max: 1 },
      ],
    },
    /**
     * For now it's a little hacky
     * In modules -> pipelineWizard -> sagas We check if it's a dummyJob then we no need to create a job on backend for this one
     * and merge the properties into specified template - mergeTemplateId
     */
    {
      templateId: 1,
      jobType: JobType.OUTPUT_CRS,
      // Indicate where all the properties will be written, null|undefined - into root properties
      fieldName: 'coordinate_reference_systems',
      mergeTemplateId: 0,
      position: TEMPLATE_POSITION.END,
      inputs: [],
      outputs: [],
      display: displayOutputCRSStep,
    },
    {
      templateId: 100,
      jobType: JobType.PIPELINE_SETTINGS,
      fieldName: '',
      inputs: [],
      outputs: [],
    },
    {
      templateId: 8,
      jobType: JobType.CALIBRATION,
      fieldName: '',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
      // We shouldn't display step only if there is no calibration for the company
      display: state => {
        /*
        if (isProductionEnvironment()) {
          return false
        }
        */
        const calibrations = getCompanyCalibrationsForCurrentProject(state)
        if (!calibrations || calibrations.length <= 0) {
          return false
        }
        return true
      },
    },
    {
      templateId: 7,
      jobType: JobType.IMU,
      fieldName: 'imu',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
      // We shouldn't display step only if trajectory artifacts have different imu_orientation property
      display: state => {
        const trajectoryArtifacts = getTrajectoryArtifacts(state)
        const cloneJobOptions = getCloneJobOptions(state, 7)
        // We are currently cloning pipeline
        if (cloneJobOptions) {
          return true
        }
        if (trajectoryArtifacts.length <= 0) return false
        const isSameIMU = isTrajectoryArtifactHasSameIMUOrientation(trajectoryArtifacts)
        return !isSameIMU
      },
    },
    {
      templateId: 2,
      jobType: JobType.LIDAR_SETTINGS,
      fieldName: 'lidar_settings',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
    },
    {
      templateId: 3,
      jobType: JobType.FLIGHTLINES,
      fieldName: 'flightlines',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
    },
    {
      templateId: 4,
      jobType: JobType.OPTIMIZATION,
      fieldName: 'optimization',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
    },
    {
      templateId: 5,
      jobType: JobType.GROUND_CLASSIFICATION,
      title: job => (
        <Typography align='left' style={{ display: 'flex', alignItems: 'center' }}>
          {prettifyJobType(job.jobType)}
          <WikiButton href={Wiki.spatialFuser} onClick={e => { e.stopPropagation() }}/>
        </Typography>
      ),
      fieldName: 'filtering',
      // Conditional displaying
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
    },
    {
      templateId: 6,
      jobType: JobType.PRODUCTS,
      fieldName: 'products',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
      position: TEMPLATE_POSITION.END,
    },
    /*
    {
      templateId: 9,
      jobType: JobType.LOCAL_CRS,
      fieldName: '',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
      position: TEMPLATE_POSITION.END,
      display: state => {
        if (isProductionEnvironment()) {
          return false
        }
        return isAdmin(state)
      },
    },
    */
  ],
}

/*
const testTemplate = {
  name: 'Test Template',
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.TEST_ARTIFACT_INPUT },
    { templateId: 1, artifactType: ArtifactTypes.TEST_ARTIFACT_OUTPUT },
  ],
  jobs: [{
    templateId: 0,
    jobType: 'test_job_please_ignore',
    inputs: [{ templateId: 0, min: 1, max: 1 }],
    outputs: [{ templateId: 1, min: 1, max: 1 }],
  }],
}

const potreeTemplate = {
  name: 'Cloud Viewer',
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.POINTCLOUD },
    { templateId: 1, artifactType: ArtifactTypes.POTREE },
  ],
  jobs: [{
    templateId: 0,
    jobType: JobType.POTREE_CONVERTER,
    inputs: [{ templateId: 0, min: 1, max: 1 }],
    outputs: [{ templateId: 1, min: 1, max: 1 }],
  }],
}
*/

export const classificationTemplate = {
  type: PipelineWizardType.CLASSIFICATION,
  title: i18n.t('templates.jobs.classification'),
  name: i18n.t('templates.jobs.classification_trial'),
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.POINTCLOUD },
    {
      templateId: 1,
      artifactType: ArtifactTypes.POINTCLOUD,
      getArtifactName: (artifacts, pipeline) => {
        return i18n.t('templates.artifactName.pointcloudClassifiedOutput', { pipelineName: pipeline.name })
      },
    },
  ],
  jobs: [
    {
      templateId: 0,
      jobType: JobType.CLASSIFICATION,
      inputs: [{ templateId: 0, min: 1, max: 1 }],
      outputs: [{ templateId: 1, min: 1, max: 1 }],
    },
  ],
}

export const pointcloudOptimizationTemplate = {
  type: PipelineWizardType.POINTCLOUD_OPTIMIZATION,
  title: i18n.t('templates.jobs.pointcloud_optimization'),
  name: i18n.t('templates.jobs.pointcloud_optimization'),
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.POINTCLOUD },
    { templateId: 1, artifactType: ArtifactTypes.TRAJECTORY },
    { templateId: 2, artifactType: ArtifactTypes.GROUND_CONTROL_POINTS },
    {
      templateId: 3,
      artifactType: ArtifactTypes.POINTCLOUD,
      getArtifactName: (artifacts, pipeline) => {
        return i18n.t('templates.artifactName.pointcloudClassifiedOutput', { pipelineName: pipeline.name })
      },
    },
    { templateId: 4, artifactType: ArtifactTypes.GEOTIFF },
  ],
  jobs: [
    {
      templateId: 0,
      jobType: JobType.POINTCLOUD_OPTIMIZATION,
      inputs: [
        {
          templateId: 0,
          min: 1,
          max: 1,
        },
        {
          templateId: 1,
          min: 1,
          max: 100,
        },
        {
          templateId: 2,
          min: 0,
          max: 1,
        },
        {
          templateId: 4,
          min: 0,
          max: 1,
        },
      ],
      outputs: [{ templateId: 3, min: 1, max: 1 }],
    },
    {
      templateId: 1,
      jobType: JobType.IMU,
      fieldName: 'imu',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
      // We shouldn't display step only if trajectory artifacts have different imu_orientation property
      /*
      display: state => {
        const trajectoryArtifacts = getTrajectoryArtifacts(state)
        const cloneJobOptions = getCloneJobOptions(state, 7)
        // We are currently cloning pipeline
        if (cloneJobOptions) {
          return true
        }
        if (trajectoryArtifacts.length <= 0) return false
        const isSameIMU = isTrajectoryArtifactHasSameIMUOrientation(trajectoryArtifacts)
        return !isSameIMU
      },
      */
    },
    {
      templateId: 2,
      jobType: JobType.OUTPUT_CRS,
      // Indicate where all the properties will be written, null|undefined - into root properties
      fieldName: 'coordinate_reference_systems',
      mergeTemplateId: 0,
      position: TEMPLATE_POSITION.END,
      inputs: [],
      outputs: [],
      display: displayOutputCRSStep,
    },
    {
      templateId: 3,
      jobType: JobType.FLIGHTLINES,
      fieldName: 'flightlines',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
    },
    {
      templateId: 4,
      jobType: JobType.OPTIMIZATION_POINTCLOUD,
      fieldName: 'optimization',
      mergeTemplateId: 0,
      display: true,
      inputs: [],
      outputs: [],
    },
    {
      templateId: 5,
      jobType: JobType.PRODUCTS,
      fieldName: 'products',
      mergeTemplateId: 0,
      inputs: [],
      outputs: [],
      position: TEMPLATE_POSITION.END,
    },
  ],
}

export const orthoPhotosTemplate = {
  type: PipelineWizardType.ORTHOIMAGE_GENERATION,
  title: i18n.t('templates.jobs.orthoimage_generation'),
  name: i18n.t('templates.jobs.orthoimage_generation'),
  artifacts: [
    { templateId: 0, artifactType: ArtifactTypes.CAMERA_DATA, display: false },
    { templateId: 1, artifactType: ArtifactTypes.POINTCLOUD, display: false },
    { templateId: 2, artifactType: ArtifactTypes.GEOTIFF },
  ],
  jobs: [
    {
      templateId: 0,
      display: true,
      jobType: JobType.ORTHOIMAGE_GENERATION,
      inputs: [
        {
          templateId: 0,
          min: 1,
          max: 1000,
        },
        {
          templateId: 1,
          min: 0,
          max: 1000,
        },
      ],
      outputs: [{ templateId: 2, min: 1, max: 1 }],
    },
  ],
}

/**
 * This list contains all pipeline templates known to the frontend.
 * In the future this will be replaced by a call to the backend, which will
 * return a list of pipelines as JSON.
 */
export const pipelineTemplates = [
  navlabTemplate1,
  navlabApplanixTemplate1,
  spatialFuserTemplate1,
  classificationTemplate,
  pointcloudOptimizationTemplate,
  ...(isProductionEnvironment() ? [] : [orthoPhotosTemplate]),
  // ...(config.ENV === Env.PRODUCTION ? [] : [testTemplate, potreeTemplate])
]
