// Project deps
import { Axis } from 'components/CoordinateField/utils.js'
import { LatLonPositionCoordinatesLabel, TransformedPositionCoordinatesLabel } from 'types/position'
import { Coordinates, CoordinateTypes, MapUnitNameToAbbr, REF_STATION_BACKEND_NAME } from 'templates/constants'
import { DataType } from 'types/form'
// Local deps
import { onChangePosition } from './CRS/utils' // shouldRenderXYZ, getTabTemplate,
import { CRSTag, CRSFields } from './CRS/constants' // tabsMap
import {
  coordinateFieldDisabled,
  getGcpCoordinate,
  isCustomAnalyzeSelected,
  selectFromAnalyzed,
  getTransformedField,
  getCloneArtifactOptionValue,
  isAnalyzedTypeSelected,
} from './utils'
import { getBackendString } from 'utils/coordinateSystem'
import { getUnits } from 'modules/app/selectors'

function getOption (state, values, formTemplate, option, altOption) {
  // const xyz = shouldRenderXYZ(state, values, formTemplate, option)
  if (isCustomAnalyzeSelected(values)) {
    // const tab = values.tab
    // const tabTemplate = getTabTemplate(tabsMap[tab], values[CRSFields.TABS_TEMPLATE])
    const selectedCoordinatesTypeValue = values[CRSFields.COORDINATES_CHOOSER]
    if (selectedCoordinatesTypeValue === CoordinateTypes.LAT_LON) {
      return option
    }
    return altOption
    // PROJ or WKT tab selected so we need to display fields based on CRSFields.COORDINATES_CHOOSER field
    /*
    if (tabTemplate && tabTemplate.isCustomCrsTab && selectedCoordinatesTypeValue) {
      if (selectedCoordinatesTypeValue === CoordinateTypes.LAT_LON) {
        return option
      }
      return altOption
    } else {
      return xyz ? altOption : option
    }
    */
  }
  return option
}

const getMetric = (state, values, unitsName) => {
  let unitsTemp = values[REF_STATION_BACKEND_NAME][unitsName]
  unitsTemp = typeof unit_v !== 'string'
    ? getBackendString(unitsTemp)
    : unitsTemp
  const units = unitsTemp ? getUnits(state).find(unit => getBackendString(unit) === unitsTemp) || null : null
  return units ? ` [${MapUnitNameToAbbr[units.name] || units.name || 'm'}]` : ''
}

const getXName = (state, values) => {
  console.log(values)
  if (!values) {
    return 'X'
  }
  if (!isCustomAnalyzeSelected(values) || values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.LAT_LON) {
    return LatLonPositionCoordinatesLabel.LONGITUDE
  }
  if (values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.EASTING_NORTHING) {
    return 'Easting ' + getMetric(state, values, CRSFields.H_UNITS)
  }
  return 'X'
}

const getYName = (state, values) => {
  if (!values) {
    return 'Y'
  }
  if (!isCustomAnalyzeSelected(values) || values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.LAT_LON) {
    return LatLonPositionCoordinatesLabel.LATITUDE
  }
  if (values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.EASTING_NORTHING) {
    return 'Northing ' + getMetric(state, values, CRSFields.H_UNITS)
  }
  return 'Y'
}

const getHeightName = (state, values) => {
  if (!values) {
    return 'Z'
  }
  if (isAnalyzedTypeSelected(values)) {
    return 'Height [m]'
  } else if (
    !isCustomAnalyzeSelected(values) ||
    values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.EASTING_NORTHING ||
    values[CRSFields.COORDINATES_CHOOSER] === CoordinateTypes.LAT_LON
  ) {
    return 'Height' + getMetric(state, values, CRSFields.V_UNITS)
  }
  return 'Z'
}

export const coordinatesTemplate = {
  // The following three fields can be used to input the coordinates for the reference station manually or will
  // be displayed as disabled and contain the corresponding precomputed coordinates if the user decided to use
  // a set of precomputed coordinates.
  [Coordinates.LONGITUDE]: {
    axis: Axis.Longitude,
    coordinateType: CoordinateTypes.LAT_LON,
    name: getXName,
    tag: CRSTag.COORDINATE,
    dataType: DataType.COORDINATE,
    disabled: coordinateFieldDisabled,
    initialValue: (state, artifactId, options) => {
      const { extraProps = {} } = options
      const { clone } = extraProps
      if (clone) return getCloneArtifactOptionValue(state, artifactId, options, Coordinates.LONGITUDE, '0')
      return '0'
    },
    onChange: onChangePosition(),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        (analyzeData['x'] || '0').toString(),
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        selectFromAnalyzed(original, state, formValues, extra, Coordinates.LONGITUDE),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
    altOption: (state, values, formTemplate, option) => {
      const alternativeOption = {
        ...option,
        name: getXName,
        tag: CRSTag.COORDINATE,
        dataType: DataType.FLOAT,
        precision: 3,
      }
      return getOption(state, values, formTemplate, option, alternativeOption)
    },
  },
  [Coordinates.LATITUDE]: {
    axis: Axis.Latitude,
    name: getYName,
    tag: CRSTag.COORDINATE,
    dataType: DataType.COORDINATE,
    disabled: coordinateFieldDisabled,
    initialValue: (state, artifactId, options) => {
      const { extraProps = {} } = options
      const { clone } = extraProps
      if (clone) return getCloneArtifactOptionValue(state, artifactId, options, Coordinates.LATITUDE, '0')
      return '0'
    },
    onChange: onChangePosition(),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        (analyzeData['y'] || '0').toString(),
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        selectFromAnalyzed(original, state, formValues, extra, Coordinates.LATITUDE),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
    altOption: (state, values, formTemplate, option) => {
      const alternativeOption = {
        ...option,
        name: getYName,
        dataType: DataType.FLOAT,
        precision: 3,
      }
      return getOption(state, values, formTemplate, option, alternativeOption)
    },
  },
  [Coordinates.ALTITUDE]: {
    name: getHeightName,
    tag: CRSTag.COORDINATE,
    dataType: DataType.FLOAT,
    precision: 3,
    disabled: coordinateFieldDisabled,
    initialValue: (state, artifactId, options) => {
      const { extraProps = {} } = options
      const { clone } = extraProps
      if (clone) return getCloneArtifactOptionValue(state, artifactId, options, Coordinates.ALTITUDE, '0')
      return '0'
    },
    onChange: onChangePosition(),
    transformOnChangeOf: ['analyze'],
    transform: getTransformedField(
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        (analyzeData['z'] || '0').toString(),
      (original, state, formValues, extra, formTemplate, name, oldValues, analyzeData) =>
        selectFromAnalyzed(original, state, formValues, extra, Coordinates.ALTITUDE),
    ),
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
    altOption: (state, values, formTemplate, option) => {
      const alternativeOption = {
        ...option,
        name: getHeightName,
      }
      return getOption(state, values, formTemplate, option, alternativeOption)
    },
  },
}

export const transformedCoordinatesTemplate = {
  [CRSFields.LONGITUDE_TRANSFORMED]: {
    name: TransformedPositionCoordinatesLabel.LONGITUDE,
    dataType: DataType.FLOAT,
    precision: 5,
    optional: true,
    invisible: coordinateFieldDisabled,
    disabled: true,
    initialValue: 0,
    getValue: (state, value, values, extra) => getGcpCoordinate(state, value, values, extra, 'lon'),
    InputProps: {
      disableUnderline: true,
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
  },
  [CRSFields.LATITUDE_TRANSFORMED]: {
    name: TransformedPositionCoordinatesLabel.LATITUDE,
    dataType: DataType.FLOAT,
    precision: 5,
    optional: true,
    invisible: coordinateFieldDisabled,
    disabled: true,
    initialValue: 0,
    getValue: (state, value, values, extra) => getGcpCoordinate(state, value, values, extra, 'lat'),
    InputProps: {
      disableUnderline: true,
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
  },
  [CRSFields.ALTITUDE_TRANSFORMED]: {
    name: TransformedPositionCoordinatesLabel.ALTITUDE,
    dataType: DataType.FLOAT,
    precision: 5,
    optional: true,
    disabled: true,
    invisible: coordinateFieldDisabled,
    initialValue: 0,
    getValue: (state, value, values, extra) => getGcpCoordinate(state, value, values, extra, 'z'),
    InputProps: {
      disableUnderline: true,
    },
    gridProps: {
      xs: 12,
      sm: 12,
      md: 4,
      lg: 4,
    },
  },
}
