// External dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
// Material UI dependencies.
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
// Project deps
import { tabMap, getTabPath } from 'templates/tabs'

const Navigation = props => {
  const { fullWidth = false } = props
  return (
    <Switch>
      <Route path='/error' render={() => <RenderNav props={props} fullWidth/>} />
      <Route path='/dashboard' render={() => <RenderNav props={props} basicUrl='dashboard' fullWidth />} />
      <Route path='/statistics' render={() => <RenderNav props={props} basicUrl='statistics' fullWidth />} />
      <Route path='/payments' render={() => <RenderNav props={props} basicUrl='payments' fullWidth />} />
      <Route path='/projects/:project_id' render={() => <RenderNav props={props} basicUrl='projects' idFieldName='project_id' fullWidth />} />
      <Route path='/company/:company_id/share_projects' render={() => null} />
      <Route path='/company/:company_id' render={() => <RenderNav props={props} basicUrl='company' idFieldName='company_id' fullWidth />} />
      <Route path='/users/:user_id' render={() => <RenderNav props={props} basicUrl='users' idFieldName='user_id' fullWidth />} />
      <Route path='/users' render={() => <RenderNav props={props} fullWidth />} />
      { fullWidth && <Route path='/' render={() => <RenderNav props={props} /> } /> }
    </Switch>
  )
}

Navigation.propTypes = {
  fullWidth: PropTypes.bool,
}

function RenderNav (props) {
  const { props: navigationProps, basicUrl, idFieldName } = props
  const { match, classes, tabsValue, onTabsChange } = navigationProps
  const tabs = tabMap[basicUrl]
  return (
    tabs && tabs.length > 0 ? (
      <Tabs
        value={tabsValue}
        onChange={onTabsChange}
        variant='scrollable'
        style={{ width: '100%' }}
        textColor='inherit'>
        { tabs.map(tab => {
          const { label, display = true } = tab
          const path = getTabPath(tab.path, match.params[idFieldName])
          const shouldDisplay = typeof display === 'function' ? display(navigationProps) : display
          const isComponentTab = typeof label === 'function'
          const Component = isComponentTab && label
          return (
            shouldDisplay &&
              <Tab
                id={path}
                key={path}
                textColor='inherit'
                component='a'
                href={path}
                label={isComponentTab ? <Component /> : label}
                // style={{ wordBreak: isFullWidth ? 'inherit' : 'break-word' }}
                classes={{
                  root: classes.tabsRoot,
                  selected: classes.tabsSelected,
                }}
              />
          )
        })}
      </Tabs>
    ) : null
  )
}

RenderNav.propTypes = {
  props: PropTypes.shape({
    match: PropTypes.object,
    classes: PropTypes.object,
    tabsValue: PropTypes.number,
    onTabsChange: PropTypes.func,
  }),
  basicUrl: PropTypes.string,
  idFieldName: PropTypes.string,
}

export default Navigation
