import { createReducer } from 'reduxsauce'
// Project deps
import { convertRawPosition } from 'types/position'
import { getRefStationDatumsMap } from 'components/PipelineWizard/utils'
import { ArtifactTypes, isRefStationArtifact, isTrajectoryArtifact } from 'types/artifacts'
import { findById, makeUnique, removeById } from 'utils/list'
// Local deps
import { INITIAL_STATE } from './initialState'
import { PipelineWizardTypes } from './actions'

const getNewDatums = (artifactType, selectedArtifacts, selectedDatums, artifactOptions, appState) => {
  // If uses selected trajectory artifact we should also update the next to properties
  if (isTrajectoryArtifact(artifactType)) {
    return selectedArtifacts.reduce((allDatums, art) =>
      art.properties.trajectory_datum
        ? { ...allDatums, [art.properties.trajectory_datum.code]: art.properties.trajectory_datum }
        : allDatums
    , {})
  }
  // If uses selected reference station artifact we should also update the next to properties
  if (isRefStationArtifact(artifactType)) {
    return getRefStationDatumsMap(selectedArtifacts, artifactOptions, appState)
  }
  return selectedDatums
}

export const createStartPipelineLoading = state =>
  state.merge({
    createPipelineIsLoading: true,
  })

export const createStartPipelineSuccess = state =>
  state.merge({
    createPipelineIsLoading: false,
  })

export const createStartPipelineFailure = state =>
  state.merge({
    createPipelineIsLoading: false,
  })

export const changePipelineCreationStatus = (state, { creationStage, okay, errorMessage }) =>
  state.merge({
    pipelineCreation: {
      okay,
      creationStage,
      errorMessage,
    },
  })

export const updatePipelineTemplate = (state, { pipelineTemplate }) =>
  state.merge({
    jobOptions: {},
    additionalJobRunOptionsByArtifacts: {},
    additionalJobIoOptionsByArtifacts: {},
    navIntervalIndex: {},
    selectedTemplateArtifacts: {},
    configureStep: 0,
    pipelineTemplate,
  })

export const deselectAllArtifacts = state => state.merge({
  selectedTemplateArtifacts: {},
  selectedArtifactsByType: {},
  selectedDatumsByType: {},
})

export const selectArtifact = (state, { appState, templateArtifactId, artifactId, artifact }) => {
  const artifactOptions = state.get('artifactOptions')
  const selectedTemplateArtifacts = state.get('selectedTemplateArtifacts')
  const selectedArtifactsByType = state.get('selectedArtifactsByType')
  const selectedDatumsByType = state.get('selectedDatumsByType')
  const artifactType = artifact.artifactType

  const newSelectedArtifacts = selectedArtifactsByType[artifactType] || []
  newSelectedArtifacts.push(artifact)
  let newSelectedDatums = selectedDatumsByType[artifactType] || {}
  newSelectedDatums = getNewDatums(artifactType, newSelectedArtifacts, newSelectedDatums, artifactOptions, appState)

  return state.merge({
    selectedTemplateArtifacts: {
      ...selectedTemplateArtifacts,
      [templateArtifactId]: [
        ...(selectedTemplateArtifacts[templateArtifactId] || []),
        artifactId,
      ],
    },
    selectedArtifactsByType: {
      ...selectedArtifactsByType,
      [artifactType]: newSelectedArtifacts,
    },
    selectedDatumsByType: {
      ...selectedDatumsByType,
      [artifactType]: newSelectedDatums,
    },
  })
}

export const deselectArtifact = (state, { appState, templateArtifactId, artifactId, artifact }) => {
  const artifactOptions = state.get('artifactOptions')
  const selectedTemplateArtifacts = state.get('selectedTemplateArtifacts')
  const selectedArtifactsByType = state.get('selectedArtifactsByType')
  const selectedDatumsByType = state.get('selectedDatumsByType')
  const artifactType = artifact.artifactType

  const newSelectedArtifacts = removeById(artifactId, selectedArtifactsByType[artifactType] || [])
  let newSelectedDatums = selectedDatumsByType[artifactType] || {}
  newSelectedDatums = getNewDatums(artifactType, newSelectedArtifacts, newSelectedDatums, artifactOptions, appState)

  const oldSelection = selectedTemplateArtifacts[templateArtifactId] || []
  const filteredSelection = {
    ...selectedTemplateArtifacts,
    [templateArtifactId]: oldSelection.filter(id => id !== artifactId),
  }

  return state.merge({
    selectedTemplateArtifacts: filteredSelection,
    selectedArtifactsByType: {
      ...selectedArtifactsByType,
      [artifactType]: newSelectedArtifacts,
    },
    selectedDatumsByType: {
      ...selectedDatumsByType,
      [artifactType]: newSelectedDatums,
    },
  })
}

export const selectArtifacts = (state, { appState, templateArtifactId, artifacts }) => {
  const artifactOptions = state.get('artifactOptions')
  const selectedTemplateArtifacts = state.get('selectedTemplateArtifacts')
  const selectedArtifactsByType = state.get('selectedArtifactsByType')
  const selectedDatumsByType = state.get('selectedDatumsByType')
  const artifactType = artifacts[0].artifactType

  const newSelectedArtifacts = selectedArtifactsByType[artifactType] || []
  artifacts.forEach(art => newSelectedArtifacts.push(art))

  let newSelectedDatums = selectedDatumsByType[artifactType] || {}
  newSelectedDatums = getNewDatums(artifactType, newSelectedArtifacts, newSelectedDatums, artifactOptions, appState)

  return state.merge({
    selectedTemplateArtifacts: {
      ...selectedTemplateArtifacts,
      [templateArtifactId]: [
        ...(selectedTemplateArtifacts[templateArtifactId] || []),
        ...artifacts.map(art => art.id),
      ],
    },
    selectedArtifactsByType: {
      ...selectedArtifactsByType,
      [artifactType]: newSelectedArtifacts,
    },
    selectedDatumsByType: {
      ...selectedDatumsByType,
      [artifactType]: newSelectedDatums,
    },
  })
}

export const selectDeselectArtifacts = (state, { appState, templateArtifactId, artifactId, artifact, deselectArtifacts }) => {
  const artifactOptions = state.get('artifactOptions')
  const selectedTemplateArtifacts = state.get('selectedTemplateArtifacts')
  const selectedArtifactsByType = state.get('selectedArtifactsByType')
  const selectedDatumsByType = state.get('selectedDatumsByType')
  const artifactType = artifact.artifactType

  const newSelectedArtifacts = (selectedArtifactsByType[artifactType] || []).filter(art => !findById(art.id, deselectArtifacts))
  newSelectedArtifacts.push(artifact)
  let newSelectedDatums = selectedDatumsByType[artifactType] || {}
  newSelectedDatums = getNewDatums(artifactType, newSelectedArtifacts, newSelectedDatums, artifactOptions, appState)

  const oldSelection = selectedTemplateArtifacts[templateArtifactId] || []
  const filteredSelection = {
    ...selectedTemplateArtifacts,
    [templateArtifactId]: [
      ...oldSelection.filter(id => !findById(id, deselectArtifacts)),
      artifactId,
    ],
  }

  return state.merge({
    selectedTemplateArtifacts: filteredSelection,
    selectedArtifactsByType: {
      ...selectedArtifactsByType,
      [artifactType]: newSelectedArtifacts,
    },
    selectedDatumsByType: {
      ...selectedDatumsByType,
      [artifactType]: newSelectedDatums,
    },
  })
}

export const deselectArtifacts = (state, { appState, templateArtifactId, artifacts }) => {
  const artifactOptions = state.get('artifactOptions')
  const selectedTemplateArtifacts = state.get('selectedTemplateArtifacts')
  const selectedArtifactsByType = state.get('selectedArtifactsByType')
  const selectedDatumsByType = state.get('selectedDatumsByType')
  const artifactType = artifacts[0].artifactType

  const newSelectedArtifacts = (selectedArtifactsByType[artifactType] || []).filter(art => !findById(art.id, artifacts))
  let newSelectedDatums = selectedDatumsByType[artifactType] || {}
  newSelectedDatums = getNewDatums(artifactType, newSelectedArtifacts, newSelectedDatums, artifactOptions, appState)

  const oldSelection = selectedTemplateArtifacts[templateArtifactId] || []
  const filteredSelection = {
    ...selectedTemplateArtifacts,
    [templateArtifactId]: oldSelection.filter(id => !findById(id, artifacts)),
  }

  return state.merge({
    selectedTemplateArtifacts: filteredSelection,
    selectedArtifactsByType: {
      ...selectedArtifactsByType,
      [artifactType]: newSelectedArtifacts,
    },
    selectedDatumsByType: {
      ...selectedDatumsByType,
      [artifactType]: newSelectedDatums,
    },
  })
}

export const updateArtifactOptions = (state, { appState, artifact, artifactId, artifactOptions, valid, rawValues, valuesToSend }) => {
  const selectedReferenceStationArtifacts = state.get('selectedArtifactsByType')[ArtifactTypes.REFERENCE_STATION] || []
  let selectedDatumsByType = state.get('selectedDatumsByType')
  const artifactType = artifact.artifactType

  const newArtifactOptions = {
    ...state.get('artifactOptions'),
    [artifactId]: {
      values: artifactOptions,
      valid,
      rawValues,
      valuesToSend,
    },
  }

  if (isRefStationArtifact(artifactType)) {
    selectedDatumsByType = {
      ...selectedDatumsByType,
      [artifactType]: getRefStationDatumsMap(selectedReferenceStationArtifacts, newArtifactOptions, appState),
    }
  }

  return state.merge({
    artifactOptions: newArtifactOptions,
    selectedDatumsByType,
  })
}

export const updateArtifactsOptions = (state, { appState, artifacts, artifactOptions, valid, rawValues, valuesToSend }) => {
  const selectedReferenceStationArtifacts = state.get('selectedArtifactsByType')[ArtifactTypes.REFERENCE_STATION] || []
  let selectedDatumsByType = state.get('selectedDatumsByType')
  const [firstArtifact] = artifacts
  const artifactType = firstArtifact.artifactType
  const newArtifactOptions = {
    ...state.get('artifactOptions'),
    ...artifacts.reduce((allArtifacts, artifact) => {
      return {
        ...allArtifacts,
        [artifact.transformedId || artifact.id]: {
          values: artifactOptions,
          valid,
          rawValues,
          valuesToSend,
        },
      }
    }, {}),
  }

  if (isRefStationArtifact(artifactType)) {
    selectedDatumsByType = {
      ...selectedDatumsByType,
      [artifactType]: getRefStationDatumsMap(selectedReferenceStationArtifacts, newArtifactOptions, appState),
    }
  }

  return state.merge({
    artifactOptions: newArtifactOptions,
    selectedDatumsByType,
  })
}

export const updateJobOptions = (state, { templateJobId, jobOptions, valid = true, rawValues }) => {
  const stateJobOptions = state.get('jobOptions')
  const concreteJobOptions = stateJobOptions[templateJobId] || { values: {}, valid: false, rawValues: {} }
  return state.merge({
    jobOptions: {
      ...stateJobOptions,
      [templateJobId]: {
        ...concreteJobOptions,
        values: {
          ...concreteJobOptions.values,
          ...jobOptions,
        },
        rawValues: {
          ...concreteJobOptions.rawValues,
          ...rawValues,
        },
        valid,
      },
    },
  })
}

export const gotoConfigureStep = (state, { step }) => {
  return state.merge({
    configureStep: step,
  })
}

export const gotoWizardStep = (state, { step, key, prevStepId }) => {
  const doneSteps = state.get('doneSteps')
  return state.merge({
    wizardStep: step,
    doneSteps: {
      ...doneSteps,
      [key]: makeUnique([...(doneSteps[key] || []), prevStepId]),
    },
  })
}

export const setPreselectState = (state, { preselect }) => state.merge({ preselect })

export const resetState = state => state.merge({
  ...INITIAL_STATE.toObject(),
})

export const getGCPLoading = state =>
  state.merge({
    getGCPIsLoading: true,
    gcp: null,
    getGCPError: null,
  })
export const getGCPSuccess = (state, { gcp, id }) =>
  state.merge({
    gcps: {
      [id]: {
        ...gcp,
        points: gcp.points.map(convertRawPosition),
      },
    },
    getGCPIsLoading: false,
  })
export const getGCPFailure = (state, { errorMessage }) =>
  state.merge({
    getGCPError: errorMessage,
    getGCPIsLoading: false,
  })

export const setAdditionalJobRunOptions = (state, { artifactId, options }) => state.merge({
  additionalJobRunOptionsByArtifacts: {
    ...state.get('additionalJobRunOptionsByArtifacts'),
    [artifactId]: options,
  },
})

export const setAdditionalJobIoOptions = (state, { artifactId, options }) => state.merge({
  additionalJobIoOptionsByArtifacts: {
    ...state.get('additionalJobIoOptionsByArtifacts'),
    [artifactId]: options,
  },
})

export const setNavIntervalIndex = (state, { artifactId, startIndex, endIndex }) => state.merge({
  navIntervalIndex: {
    ...state.get('navIntervalIndex'),
    [artifactId]: [startIndex, endIndex],
  },
})

export const clonePipeline = state => state.merge({
  ...INITIAL_STATE.toObject(),
  previousState: state.toObject(),
  cloningPipeline: true,
})

export const resetClonePipeline = state => {
  return state.merge({
    ...(state.get('previousState') || {}),
  })
}

export const setCloneOptions = (state, { options }) => state.merge({
  cloneOptions: {
    artifactOptions: options.artifactOptions,
    jobOptions: options.jobOptions,
  },
})

export const reducer = createReducer(INITIAL_STATE, {
  [PipelineWizardTypes.RESET_STATE]: resetState,

  [PipelineWizardTypes.CHANGE_CREATION_STATUS]: changePipelineCreationStatus,
  [PipelineWizardTypes.UPDATE_PIPELINE_TEMPLATE]: updatePipelineTemplate,

  [PipelineWizardTypes.UPDATE_ARTIFACT_OPTIONS]: updateArtifactOptions,
  [PipelineWizardTypes.UPDATE_ARTIFACTS_OPTIONS]: updateArtifactsOptions,
  [PipelineWizardTypes.UPDATE_JOB_OPTIONS]: updateJobOptions,
  [PipelineWizardTypes.GOTO_CONFIGURE_STEP]: gotoConfigureStep,
  [PipelineWizardTypes.GOTO_WIZARD_STEP]: gotoWizardStep,
  [PipelineWizardTypes.SET_PRESELECT_STATE]: setPreselectState,

  [PipelineWizardTypes.DESELECT_ALL_ARTIFACTS]: deselectAllArtifacts,

  [PipelineWizardTypes.SELECT_ARTIFACT_FOR_TEMPLATE]: selectArtifact,
  [PipelineWizardTypes.DESELECT_ARTIFACT_FOR_TEMPLATE]: deselectArtifact,

  [PipelineWizardTypes.SELECT_ARTIFACTS_FOR_TEMPLATE]: selectArtifacts,
  [PipelineWizardTypes.DESELECT_ARTIFACTS_FOR_TEMPLATE]: deselectArtifacts,

  [PipelineWizardTypes.SELECT_DESELECT_ARTIFACTS_FOR_TEMPLATE]: selectDeselectArtifacts,

  [PipelineWizardTypes.SET_ADDITIONAL_JOB_RUN_OPTIONS]: setAdditionalJobRunOptions,
  [PipelineWizardTypes.SET_ADDITIONAL_JOB_IO_OPTIONS]: setAdditionalJobIoOptions,

  [PipelineWizardTypes.SET_NAV_INTERVAL_INDEX]: setNavIntervalIndex,

  [PipelineWizardTypes.GET_GCP_LOADING]: getGCPLoading,
  [PipelineWizardTypes.GET_GCP_SUCCESS]: getGCPSuccess,
  [PipelineWizardTypes.GET_GCP_FAILURE]: getGCPFailure,

  [PipelineWizardTypes.CREATE_START_PIPELINE_LOADING]: createStartPipelineLoading,
  [PipelineWizardTypes.CREATE_START_PIPELINE_SUCCESS]: createStartPipelineSuccess,
  [PipelineWizardTypes.CREATE_START_PIPELINE_FAILURE]: createStartPipelineFailure,

  [PipelineWizardTypes.CLONE_PIPELINE]: clonePipeline,
  [PipelineWizardTypes.SET_CLONE_OPTIONS]: setCloneOptions,
  [PipelineWizardTypes.RESET_CLONE_PIPELINE]: resetClonePipeline,
})
