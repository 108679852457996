import { createReducer } from 'reduxsauce'
import { omit } from 'ramda'
import Cookies from 'js-cookie'
// Project deps
import { getToken, removeToken, setToken } from 'utils/axios'
import { mapById, removeById } from 'utils/list'
import { convertRawCompanyPermission, convertRawProjectPermission } from 'types/permissions'
import { convertRawUser } from 'types/users'
import { CompaniesTypes } from 'modules/companies/actions'
// Local deps
import { INITIAL_STATE } from './initialState'
import { UsersTypes } from './actions'
import { setTimezone } from './utils'
import { getStorageItem, removeStorageItem } from 'utils/localStorage'
import { cookieName } from 'utils/cookies'

export const getUsersLoading = state =>
  state.merge({
    getUsersIsLoading: true,
    getUsersErrorMessage: '',
  })

export const getUsersSuccess = (state, { users }) =>
  state.merge({
    users: users.map(convertRawUser),
    getUsersIsLoading: false,
    getUsersErrorMessage: null,
  })

export const getUsersFailure = (state, { errorMessage }) =>
  state.merge({
    users: [],
    getUsersIsLoading: false,
    getUsersErrorMessage: errorMessage,
  })

export const getProjectsStatisticLoading = state => state.merge({
  getProjectsStatisticIsLoading: true,
})

export const getProjectsStatisticSuccess = (state, { projectsStatistic }) =>
  state.merge({
    projectsStatistic,
    getProjectsStatisticIsLoading: false,
  })

export const getProjectsStatisticFailure = (state, { errorMessage }) => state.merge({
  getProjectsStatisticIsLoading: false,
})

export const getUserLoading = state =>
  state.merge({
    getUserIsLoading: true,
    getUserErrorMessage: '',
  })

export const getUserSuccess = (state, { user }) =>
  state.merge({
    user,
    getUserIsLoading: false,
    getUserErrorMessage: null,
  })

export const getUserFailure = (state, { errorMessage }) =>
  state.merge({
    user: null,
    getUserIsLoading: false,
    getUserErrorMessage: errorMessage,
  })

export const getMeLoading = state =>
  state.merge({
    getMeIsLoading: true,
    getMeErrorMessage: '',
  })

export const getMeSuccess = (state, { user }) => {
  const userTimeZone = user.time_zone
  if (userTimeZone) {
    setTimezone(userTimeZone)
  }
  return state.merge({
    user: convertRawUser(user),
    getMeIsLoading: false,
    getMeErrorMessage: null,
  })
}

export const getMeFailure = (state, { errorMessage }) =>
  state.merge({
    user: null,
    getMeIsLoading: false,
    getMeErrorMessage: errorMessage,
  })

export const getActiveUserLoading = state =>
  state.merge({
    getActiveUserIsLoading: true,
    getActiveUserErrorMessage: '',
  })

export const getActiveUserSuccess = (state, { user }) =>
  state.merge({
    activeUser: user,
    getActiveUserIsLoading: false,
    getActiveUserErrorMessage: null,
  })

export const setActiveUserSubscription = (state, { subscription }) => {
  return state.merge({
    // ...state,
    activeUser: {
      ...state.get('activeUser'),
      billingAgreement: subscription,
    },
  })
}

export const setActiveUserGrants = (state, { grants }) => {
  return state.merge({
    // ...state,
    activeUser: {
      ...state.get('activeUser'),
      grants,
    },
  })
}

export const getActiveUserFailure = (state, { errorMessage }) => {
  return state.merge({
    activeUser: null,
    getActiveUserIsLoading: false,
    getActiveUserErrorMessage: errorMessage,
  })
}

export const createUserLoading = state =>
  state.merge({
    createUserIsLoading: true,
    createUserErrorMessage: null,
  })

export const createUserSuccess = state =>
  state.merge({
    createUserIsLoading: false,
    createUserErrorMessage: null,
  })

export const createUserFailure = (state, { errorMessage }) =>
  state.merge({
    createUserIsLoading: false,
    createUserErrorMessage: errorMessage,
  })

// login
export const loginLoading = state =>
  state.merge({
    loginIsLoading: true,
    loginErrorMessage: null,
  })

export const loginSuccess = (state, { token, user }) => {
  setToken(token, { setExpDate: true })
  return state.merge({
    passwordNotSet: false,
    token,
    user,
    loginIsLoading: false,
    loginErrorMessage: null,
  })
}

export const loginFailure = (state, { errorMessage, passwordNotSet }) => {
  return state.merge({
    ...(passwordNotSet && { passwordNotSet }),
    token: null,
    user: null,
    loginIsLoading: false,
    loginErrorMessage: errorMessage,
  })
}

// login using two-factor auth
export const twoFactorLoginLoading = state =>
  state.merge({
    twoFactorLoginIsLoading: true,
    loginErrorMessage: null,
  })

export const twoFactorLoginSuccess = (state, { token, user, remember_2fa_token, remember_2fa_token_exp }) => {
  setToken(token, { setExpDate: true, remember_2fa_token_exp, remember_2fa_token })
  return state.merge({
    passwordNotSet: false,
    token,
    user,
    loginIsLoading: false,
    twoFactorLoginIsLoading: false,
    loginErrorMessage: null,
    twoFactorLogin: false,
    tempToken: null,
  })
}

export const twoFactorLoginFailure = (state, { errorMessage, isTokenExpired }) => {
  return state.merge({
    token: null,
    user: null,
    twoFactorLogin: !isTokenExpired,
    loginIsLoading: false,
    twoFactorLoginIsLoading: false,
    loginErrorMessage: errorMessage,
  })
}

export const setTwoFactorLogin = (state, { temp_token }) => {
  return state.merge({
    twoFactorLogin: true,
    tempToken: temp_token,
  })
}

export const registerLoading = state =>
  state.merge({
    registerIsLoading: true,
    registerErrorMessage: null,
  })

export const registerSuccess = (state, { user, createdByUser }) => {
  if (!createdByUser) {
    removeStorageItem('dropboxToken')
  }
  return state.merge({
    registerIsLoading: false,
    registerErrorMessage: null,
    users: [
      convertRawUser(user),
      ...(state.get('users') || []),
    ],
  })
}

export const registerFailure = (state, { errorMessage }) =>
  state.merge({
    registerIsLoading: false,
    registerErrorMessage: errorMessage,
  })

export const logoutLoading = state =>
  state.merge({
    logoutIsLoading: true,
    logoutErrorMessage: null,
  })

export const logoutSuccess = state => {
  removeToken()
  Cookies.remove(cookieName, { path: '/', domain: '.lidarmill.com' })
  removeStorageItem('dropboxToken')
  removeStorageItem('loggedAs')
  return state.merge({
    passwordNotSet: false,
    token: null,
    user: null,
    logoutIsLoading: false,
    logoutErrorMessage: null,
  })
}

export const logoutFailure = (state, { errorMessage }) =>
  state.merge({
    token: null,
    user: null,
    logoutIsLoading: false,
    logoutErrorMessage: errorMessage,
  })

// request recovery
export const requestRecoveryLoading = state =>
  state.merge({
    requestRecoveryIsLoading: true,
    requestRecoveryErrorMessage: null,
    requestRecoveryIsSuccess: false,
  })

export const requestRecoverySuccess = state => {
  return state.merge({
    passwordNotSet: false,
    requestRecoveryIsLoading: false,
    requestRecoveryErrorMessage: null,
    requestRecoveryIsSuccess: true,
  })
}

export const requestRecoveryFailure = (state, { errorMessage }) => {
  return state.merge({
    requestRecoveryIsLoading: false,
    requestRecoveryErrorMessage: errorMessage,
    requestRecoveryIsSuccess: false,
  })
}

// recover
export const recoverLoading = state =>
  state.merge({
    recoverIsLoading: true,
    recoverErrorMessage: null,
  })

export const recoverSuccess = state => {
  return state.merge({
    recoverIsSuccess: true,
    recoverIsLoading: false,
    recoverErrorMessage: null,
  })
}

export const recoverFailure = (state, { errorMessage }) => {
  return state.merge({
    recoverIsLoading: false,
    recoverErrorMessage: errorMessage,
  })
}

// confirm email
export const confirmEmailLoading = state =>
  state.merge({
    confirmEmailIsLoading: true,
    confirmEmailErrorMessage: null,
  })

export const confirmEmailSuccess = state => {
  return state.merge({
    confirmEmailIsSuccess: true,
    confirmEmailIsLoading: false,
    confirmEmailErrorMessage: null,
  })
}

export const confirmEmailFailure = (state, { errorMessage }) => {
  return state.merge({
    confirmEmailIsLoading: false,
    confirmEmailIsSuccess: false,
    confirmEmailErrorMessage: errorMessage,
  })
}

// send confirm email
export const sendConfirmEmailLoading = state =>
  state.merge({
    sendConfirmEmailIsLoading: true,
    sendConfirmEmailErrorMessage: null,
  })

export const sendConfirmEmailSuccess = state => {
  return state.merge({
    sendConfirmEmailIsSuccess: true,
    sendConfirmEmailIsLoading: false,
    sendConfirmEmailErrorMessage: null,
  })
}

export const sendConfirmEmailFailure = (state, { errorMessage }) => {
  return state.merge({
    sendConfirmEmailIsLoading: false,
    sendConfirmEmailIsSuccess: false,
    sendConfirmEmailErrorMessage: errorMessage,
  })
}

export const updateUserLoading = state =>
  state.merge({
    updateUserSucceed: false,
    updateUserIsLoading: true,
    updateUserErrorMessage: '',
  })

export const updateUserSuccess = (state, { user }) => {
  const convertedUser = convertRawUser(user)
  const loggedUser = state.get('user')
  const users = state.get('users') || []
  const update = {
    updateUserIsLoading: false,
    updateUserErrorMessage: null,
    updateUserSucceed: true,
    user: (loggedUser && loggedUser.id === user.id) ? convertedUser : loggedUser,
    users: mapById(user.id, users, () => convertedUser),
  }

  const activeUser = state.get('activeUser')
  if (activeUser && activeUser.id === user.id) {
    update.activeUser = convertedUser
    const userTimeZone = user.time_zone
    if (userTimeZone) {
      setTimezone(userTimeZone)
    }
  }

  return state.merge(update)
}

export const updateUserFailure = (state, { errorMessage }) =>
  state.merge({
    updateUserSucceed: false,
    updateUserIsLoading: false,
    updateUserErrorMessage: errorMessage,
  })

export const setAcceptTermsFormOpen = (state, { open }) =>
  state.merge({
    acceptTermsFormOpen: open,
  })

export const getSubscriptionsLoading = (state, { userId }) => {
  return state.merge({
    subscriptionsByUserLoading: {
      ...state.get('subscriptionsByUserLoading'),
      [userId]: true,
    },
  })
}

export const getSubscriptionsSuccess = (state, { userId, subscriptions }) => {
  return state.merge({
    subscriptionsByUser: {
      ...state.get('subscriptionsByUser'),
      [userId]: subscriptions,
    },
    subscriptionsByUserLoading: {
      ...state.get('subscriptionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const getSubscriptionsFailure = (state, { userId }) => {
  return state.merge({
    subscriptionsByUserLoading: {
      ...state.get('subscriptionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const changePasswordLoading = state => state.merge({})
export const changePasswordSuccess = state => state.merge({})
export const changePasswordFailure = state => state.merge({})

export const getUserPermissionsLoading = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: true,
    },
  })
}

export const getUserPermissionsSuccess = (state, { userId, permissions }) => {
  return state.merge({
    permissionsByUser: {
      ...state.get('permissionsByUser'),
      [userId]: [
        ...permissions.company_permissions.map(convertRawCompanyPermission),
        ...permissions.project_permissions.map(convertRawProjectPermission),
      ],
    },
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const getUserPermissionsFailure = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const addProjectsPermissionLoading = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: true,
    },
  })
}
export const addProjectsPermissionSuccess = (state, { userId, permissions }) => {
  const permissionsByUser = state.get('permissionsByUser') || {}
  return state.merge({
    permissionsByUser: {
      ...permissionsByUser,
      [userId]: [
        ...(permissionsByUser[userId] || []),
        ...permissions.map(convertRawProjectPermission),
      ],
    },
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}
export const addProjectsPermissionFailure = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const addCompanyPermissionLoading = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: true,
    },
  })
}
export const addCompanyPermissionSuccess = (state, { userId, permission }) => {
  const permissionsByUser = state.get('permissionsByUser') || {}
  return state.merge({
    permissionsByUser: {
      ...permissionsByUser,
      [userId]: [
        ...(permissionsByUser[userId] || []),
        convertRawCompanyPermission(permission),
      ],
    },
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}
export const addCompanyPermissionFailure = (state, { userId }) => {
  return state.merge({
    permissionsByUserLoading: {
      ...state.get('permissionsByUserLoading'),
      [userId]: false,
    },
  })
}

export const deletePermissionLoading = (state, { userId, permissionId }) => {
  return state.merge({
    deletePermissionLoading: {
      ...state.get('deletePermissionLoading'),
      [permissionId]: true,
    },
  })
}
export const deletePermissionSuccess = (state, { userId, permissionId }) => {
  const permissionsByUser = state.get('permissionsByUser') || {}
  return state.merge({
    permissionsByUser: {
      ...permissionsByUser,
      [userId]: removeById(permissionId, (permissionsByUser[userId] || [])),
    },
    deletePermissionLoading: {
      ...state.get('deletePermissionLoading'),
      [permissionId]: true,
    },
  })
}
export const deletePermissionFailure = (state, { userId, permissionId }) => {
  return state.merge({
    deletePermissionLoading: {
      ...state.get('deletePermissionLoading'),
      [permissionId]: false,
    },
  })
}

// Get all user's CRS
export const getCompanyCRSLoading = (state, { companyId }) => state.merge({
  companyCRSLoading: {
    ...state.get('companyCRSLoading'),
    [companyId]: true,
  },
})
export const getCompanyCRSSuccess = (state, { companyId, crs }) => state.merge({
  companyCRS: {
    ...state.get('companyCRS'),
    [companyId]: crs,
  },
  companyCRSLoading: {
    ...state.get('companyCRSLoading'),
    [companyId]: false,
  },
})
export const getCompanyCRSFailure = (state, { companyId }) => state.merge({
  companyCRSLoading: {
    ...state.get('companyCRSLoading'),
    [companyId]: false,
  },
})

// Get CRS
export const getCRSLoading = (state, { crsId }) => state.merge({
  crsLoading: true,
})
export const getCRSSuccess = (state, { crsId, crs }) => {
  return state.merge({
    crs: {
      ...state.get('crs'),
      [crsId]: crs,
    },
    crsLoading: false,
  })
}
export const getCRSFailure = (state, { crsId }) => state.merge({
  crsLoading: false,
})
// Update CRS
export const updateCRSStatus = (state, { title, total, current }) => state.merge({
  updateCRSStatus: {
    title,
    total,
    current,
  },
})
export const updateCRSLoading = (state, { projectId, crsId }) => state.merge({
  updateCRSLoading: true,
  updateCRSDialogOpen: true,
})
export const updateCRSSuccess = (state, { projectId, crsId, crs }) => {
  return state.merge({
    crs: {
      ...state.get('crs'),
      [crsId]: crs,
    },
    updateCRSStatus: null,
    updateCRSDialogOpen: false,
    updateCRSLoading: false,
  })
}
export const updateCRSFailure = (state, { projectId, crsId }) => state.merge({
  updateCRSLoading: false,
  updateCRSDialogOpen: false,
})
// Delete CRS
export const deleteCRSLoading = (state, { projectId, crsId }) => state.merge({
  deleteCRSLoading: true,
})
export const deleteCRSSuccess = (state, { projectId, crsId }) => {
  return state.merge({
    crs: {
      ...omit([crsId], state.get('crs')),
    },
    deleteCRSLoading: false,
  })
}
export const deleteCRSFailure = (state, { projectId, crsId }) => state.merge({
  deleteCRSLoading: false,
})

export const getUserReleasesLoading = state =>
  state.merge({
    releasesIsLoading: true,
  })

export const getUserReleasesSuccess = (state, { releases }) => {
  const releasesByProduct = releases.reduce((all, release) => {
    const { products = [] } = release
    return products.reduce((all1, product) => ({
      ...all1,
      [product.name]: [...(all1[product.name] || []), release],
    }), all)
  }, {})
  const newReleases = Object.keys(releasesByProduct).map(productName => {
    const releases = releasesByProduct[productName]
    return {
      name: productName,
      releases: releases,
      changes: releases.reduce((all, release) => all + (release.filtered_changes || []).length, 0),
    }
  })
  const totalReleases = newReleases.reduce((all, release) => all + release.releases.length, 0)
  return state.merge({
    releases: newReleases,
    totalReleases,
    releasesIsLoading: false,
  })
}

export const getUserReleasesFailure = (state, { errorMessage }) =>
  state.merge({
    releases: [],
    releasesIsLoading: false,
  })

export const setUserSeenReleasesLoading = state => state.merge({})
export const setUserSeenReleasesSuccess = (state, { productName, numberOfReleases }) => {
  return state.merge({
    releases: state.get('releases').filter(releasesByProduct => releasesByProduct.name !== productName),
    totalReleases: state.get('totalReleases') - numberOfReleases,
  })
}
export const setUserSeenReleasesFailure = (state, { errorMessage }) => state.merge({})

export const rehydrate = (state, { payload, token }) => {
  const isLoggedAs = Boolean(+getStorageItem('loggedAs'))
  const payloadUser = payload && payload.users.get('user')
  return state.merge({
    ...INITIAL_STATE.toObject(),
    token,
    ...(payloadUser && token && { user: payloadUser }),
    isLoggedAs: isLoggedAs,
    rehydrated: true,
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [UsersTypes.GET_USER_LOADING]: getUserLoading,
  [UsersTypes.GET_USER_SUCCESS]: getUserSuccess,
  [UsersTypes.GET_USER_FAILURE]: getUserFailure,

  [UsersTypes.GET_USERS_LOADING]: getUsersLoading,
  [UsersTypes.GET_USERS_SUCCESS]: getUsersSuccess,
  [UsersTypes.GET_USERS_FAILURE]: getUsersFailure,

  [UsersTypes.GET_PROJECTS_STATISTIC_LOADING]: getProjectsStatisticLoading,
  [UsersTypes.GET_PROJECTS_STATISTIC_SUCCESS]: getProjectsStatisticSuccess,
  [UsersTypes.GET_PROJECTS_STATISTIC_FAILURE]: getProjectsStatisticFailure,

  [UsersTypes.CHANGE_PASSWORD_LOADING]: changePasswordLoading,
  [UsersTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [UsersTypes.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,

  [UsersTypes.GET_ME_LOADING]: getMeLoading,
  [UsersTypes.GET_ME_SUCCESS]: getMeSuccess,
  [UsersTypes.GET_ME_FAILURE]: getMeFailure,

  [UsersTypes.GET_ACTIVE_USER_LOADING]: getActiveUserLoading,
  [UsersTypes.GET_ACTIVE_USER_SUCCESS]: getActiveUserSuccess,
  [UsersTypes.GET_ACTIVE_USER_FAILURE]: getActiveUserFailure,

  [UsersTypes.CREATE_USER_LOADING]: createUserLoading,
  [UsersTypes.CREATE_USER_SUCCESS]: createUserSuccess,
  [UsersTypes.CREATE_USER_FAILURE]: createUserFailure,

  [UsersTypes.SET_ACTIVE_USER_SUBSCRIPTION]: setActiveUserSubscription,
  [UsersTypes.SET_ACTIVE_USER_GRANTS]: setActiveUserGrants,

  [UsersTypes.LOGIN_LOADING]: loginLoading,
  [UsersTypes.LOGIN_SUCCESS]: loginSuccess,
  [UsersTypes.LOGIN_FAILURE]: loginFailure,

  [UsersTypes.LOGOUT_LOADING]: logoutLoading,
  [UsersTypes.LOGOUT_SUCCESS]: logoutSuccess,
  [UsersTypes.LOGOUT_FAILURE]: logoutFailure,

  [UsersTypes.REGISTER_LOADING]: registerLoading,
  [UsersTypes.REGISTER_SUCCESS]: registerSuccess,
  [UsersTypes.REGISTER_FAILURE]: registerFailure,

  [UsersTypes.REQUEST_RECOVERY_LOADING]: requestRecoveryLoading,
  [UsersTypes.REQUEST_RECOVERY_SUCCESS]: requestRecoverySuccess,
  [UsersTypes.REQUEST_RECOVERY_FAILURE]: requestRecoveryFailure,

  [UsersTypes.RECOVER_LOADING]: recoverLoading,
  [UsersTypes.RECOVER_SUCCESS]: recoverSuccess,
  [UsersTypes.RECOVER_FAILURE]: recoverFailure,

  [UsersTypes.CONFIRM_EMAIL_LOADING]: confirmEmailLoading,
  [UsersTypes.CONFIRM_EMAIL_SUCCESS]: confirmEmailSuccess,
  [UsersTypes.CONFIRM_EMAIL_FAILURE]: confirmEmailFailure,

  [UsersTypes.SEND_CONFIRM_EMAIL_LOADING]: sendConfirmEmailLoading,
  [UsersTypes.SEND_CONFIRM_EMAIL_SUCCESS]: sendConfirmEmailSuccess,
  [UsersTypes.SEND_CONFIRM_EMAIL_FAILURE]: sendConfirmEmailFailure,

  [UsersTypes.UPDATE_USER_LOADING]: updateUserLoading,
  [UsersTypes.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [UsersTypes.UPDATE_USER_FAILURE]: updateUserFailure,

  [UsersTypes.SET_ACCEPT_TERMS_FORM_OPEN]: setAcceptTermsFormOpen,

  [UsersTypes.GET_SUBSCRIPTIONS_LOADING]: getSubscriptionsLoading,
  [UsersTypes.GET_SUBSCRIPTIONS_SUCCESS]: getSubscriptionsSuccess,
  [UsersTypes.GET_SUBSCRIPTIONS_FAILURE]: getSubscriptionsFailure,

  [UsersTypes.GET_USER_PERMISSIONS_LOADING]: getUserPermissionsLoading,
  [UsersTypes.GET_USER_PERMISSIONS_SUCCESS]: getUserPermissionsSuccess,
  [UsersTypes.GET_USER_PERMISSIONS_FAILURE]: getUserPermissionsFailure,

  [UsersTypes.GET_COMPANY_CRS_LOADING]: getCompanyCRSLoading,
  [UsersTypes.GET_COMPANY_CRS_SUCCESS]: getCompanyCRSSuccess,
  [UsersTypes.GET_COMPANY_CRS_FAILURE]: getCompanyCRSFailure,

  [UsersTypes.GET_CRS_LOADING]: getCRSLoading,
  [UsersTypes.GET_CRS_SUCCESS]: getCRSSuccess,
  [UsersTypes.GET_CRS_FAILURE]: getCRSFailure,

  [UsersTypes.UPDATE_CRS_STATUS]: updateCRSStatus,
  [UsersTypes.UPDATE_CRS_LOADING]: updateCRSLoading,
  [UsersTypes.UPDATE_CRS_SUCCESS]: updateCRSSuccess,
  [UsersTypes.UPDATE_CRS_FAILURE]: updateCRSFailure,

  [UsersTypes.DELETE_CRS_LOADING]: deleteCRSLoading,
  [UsersTypes.DELETE_CRS_SUCCESS]: deleteCRSSuccess,
  [UsersTypes.DELETE_CRS_FAILURE]: deleteCRSFailure,

  [CompaniesTypes.ADD_PROJECTS_PERMISSION_LOADING]: addProjectsPermissionLoading,
  [CompaniesTypes.ADD_PROJECTS_PERMISSION_SUCCESS]: addProjectsPermissionSuccess,
  [CompaniesTypes.ADD_PROJECTS_PERMISSION_FAILURE]: addProjectsPermissionFailure,

  [CompaniesTypes.ADD_COMPANY_PERMISSION_LOADING]: addCompanyPermissionLoading,
  [CompaniesTypes.ADD_COMPANY_PERMISSION_SUCCESS]: addCompanyPermissionSuccess,
  [CompaniesTypes.ADD_COMPANY_PERMISSION_FAILURE]: addCompanyPermissionFailure,

  [CompaniesTypes.DELETE_PERMISSION_LOADING]: deletePermissionLoading,
  [CompaniesTypes.DELETE_PERMISSION_SUCCESS]: deletePermissionSuccess,
  [CompaniesTypes.DELETE_PERMISSION_FAILURE]: deletePermissionFailure,

  [UsersTypes.TWO_FACTOR_LOGIN_LOADING]: twoFactorLoginLoading,
  [UsersTypes.TWO_FACTOR_LOGIN_SUCCESS]: twoFactorLoginSuccess,
  [UsersTypes.TWO_FACTOR_LOGIN_FAILURE]: twoFactorLoginFailure,

  [UsersTypes.GET_USER_RELEASES_LOADING]: getUserReleasesLoading,
  [UsersTypes.GET_USER_RELEASES_SUCCESS]: getUserReleasesSuccess,
  [UsersTypes.GET_USER_RELEASES_FAILURE]: getUserReleasesFailure,

  [UsersTypes.SET_USER_SEEN_RELEASES_LOADING]: setUserSeenReleasesLoading,
  [UsersTypes.SET_USER_SEEN_RELEASES_SUCCESS]: setUserSeenReleasesSuccess,
  [UsersTypes.SET_USER_SEEN_RELEASES_FAILURE]: setUserSeenReleasesFailure,

  [UsersTypes.SET_TWO_FACTOR_LOGIN]: setTwoFactorLogin,

  'persist/REHYDRATE': rehydrate,

  // [UsersTypes.ACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS]: activateOfflineSubscriptionSuccess,
  // [UsersTypes.DEACTIVATE_OFFLINE_SUBSCRIPTION_SUCCESS]: deactivateOfflineSubscriptionSuccess,
})
