import { call, select, takeLatest, takeEvery, fork, put } from 'redux-saga/effects'
import { path, lensPath, set, dissocPath, mergeDeepRight } from 'ramda'
import { toast } from 'react-toastify'
import i18n from 'i18n'
import { convertRawArtifact, convertRawDataDirectory, isPointcloudArtifact, isTrajectoryArtifact } from 'types/artifacts'
import config from 'config'
import axios, { axiosAccountsClient } from 'utils/axios'
import { showErrorMessage, getErrorMessage, resolveTasks } from 'utils/api'
import { getCurrentProject } from 'modules/projects/selectors'
import { token as getToken } from 'modules/users/selectors'
import ProjectsActions from 'modules/projects/actions'
// Local deps
import ArtifactsActions, { ArtifactsTypes as ArtifactsActionTypes } from './actions'
import { isNavLabJob } from 'utils/jobs'
import { isLasFileName } from 'modules/upload/file-types'
import { getArtifactProperties } from 'modules/importWizard/utils'
import { makeUnique } from 'utils/list'
import { replaceMultipleWhiteSpaces } from 'utils/baseName'
import { getArtifactDownloadLink } from 'utils/artifacts'
import { getArtifactDownloadToken } from './api'
import { convertRawRecursivePipeline } from 'types/pipelines'

function * retryPipeline (analyzingPipeline, withOptions = false) {
  if (analyzingPipeline) {
    const { data: { data: pipeline } } = yield call(axios.get, `/pipelines/${analyzingPipeline.id}/recursive`)
    const { jobs } = convertRawRecursivePipeline(pipeline)
    const [firstJob] = jobs
    if (withOptions) {
      const { last_job_run } = firstJob
      const options = ((firstJob.job_runs.find(jobRun => jobRun.id === last_job_run.id) || {}).options || {})
      yield call(axios.post, `/jobs/${firstJob.id}/job_runs`, { options })
    } else {
      yield call(axios.post, `/jobs/${firstJob.id}/job_runs`, { options: {} })
    }
  }
}

const isTrajectoryOutputArtifactWithoutIMU = artifact => {
  return isTrajectoryArtifact(artifact.artifactType) &&
    artifact.isOutputArtifact &&
    !('imu_orientation' in artifact.properties) &&
    artifact.output_pipelines.find(output_pipeline => output_pipeline.job_types.find(isNavLabJob))
}

// Get the details of artifact
export function * getArtifact ({ artifactId, onSuccess, onError }) {
  yield put(ArtifactsActions.getArtifactLoading(artifactId))
  try {
    const { data: { data: artifact } } = yield call(axios.get, `/artifacts/${artifactId}`)
    yield put(ArtifactsActions.putGetArtifact(convertRawArtifact(artifact)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    if (onError) {
      onError()
    }
    yield put(ArtifactsActions.getArtifactFailure(artifactId, getErrorMessage(e)))
  }
}

export function * getArtifacts ({ artifactIds }) {
  yield put(ArtifactsActions.getArtifactsLoading(artifactIds))
  try {
    const tasks = []
    for (const artifactId of artifactIds) {
      tasks.push(yield fork(axios.get, `/artifacts/${artifactId}`))
    }
    const artifacts = yield resolveTasks(tasks, convertRawArtifact)
    const trajectoryOutputArtifactsWithoutIMU = artifacts.filter(isTrajectoryOutputArtifactWithoutIMU)
    const otherArtifacts = artifacts.filter(artifact => !isTrajectoryOutputArtifactWithoutIMU(artifact))
    const trajectoryTasks = []
    // If trajectory artifact is not uploaded by user we should set imu_orientation to the navlab -> navigation rover -> bodyToImuRotation
    for (let i = 0; i < trajectoryOutputArtifactsWithoutIMU.length; i++) {
      const artifact = trajectoryOutputArtifactsWithoutIMU[i]
      const firstNavLabOutputPipeline =
        artifact.output_pipelines.find(output_pipeline => output_pipeline.job_types.find(isNavLabJob))
      trajectoryTasks.push(yield fork(axios.get, `/pipelines/${firstNavLabOutputPipeline.id}/recursive`))
    }
    let pipelines = yield resolveTasks(trajectoryTasks)
    pipelines = pipelines.map(convertRawRecursivePipeline)
    const newTrajectoryArtifacts = []
    for (let i = 0; i < pipelines.length; i++) {
      const outputPipeline = pipelines[i]
      const artifact = trajectoryOutputArtifactsWithoutIMU[i]
      const jobIoWithBodyToImuRotation = outputPipeline.jobs.reduce((_, job) => {
        const jobIos = job.job_ios
        return jobIos.find(jobIo => jobIo.options.bodyToImuRotation)
      }, undefined)
      if (jobIoWithBodyToImuRotation) {
        newTrajectoryArtifacts.push(
          mergeDeepRight(
            artifact,
            { properties: { imu_orientation: jobIoWithBodyToImuRotation.options.bodyToImuRotation } },
          ),
        )
      } else {
        newTrajectoryArtifacts.push(
          artifact,
        )
      }
    }
    yield put(ArtifactsActions.getArtifactsSuccess([...otherArtifacts, ...newTrajectoryArtifacts]))
  } catch (e) {
    yield put(ArtifactsActions.getArtifactsFailure(artifactIds, getErrorMessage(e)))
  }
}

// Delete individual artifact
export function * deleteArtifact ({ artifactId }) {
  yield put(ArtifactsActions.deleteArtifactLoading(artifactId))
  try {
    yield call(axios.delete, `/artifacts/${artifactId}`)
    toast.success(i18n.t('toast.artifact.deleteSuccess'))
    yield put(ArtifactsActions.deleteArtifactSuccess(artifactId))
  } catch (e) {
    showErrorMessage(e)
    yield put(ArtifactsActions.deleteArtifactFailure(artifactId, getErrorMessage(e)))
  }
}

// Update individual artifact name and properties
export function * updateArtifact ({ artifactId, name, properties, withSuccessMessage = true }) {
  yield put(ArtifactsActions.updateArtifactLoading())
  try {
    const body = {
      ...(typeof name === 'string' ? { name } : {}),
      ...(typeof properties !== 'undefined' ? { properties } : {}),
    }
    const { data: { data: artifact } } = yield call(axios.post, `/artifacts/${artifactId}`, body)
    if (withSuccessMessage) toast.success(i18n.t('toast.artifact.updateSuccess'))
    yield put(ArtifactsActions.updateArtifactSuccess(artifactId, artifact))
  } catch (e) {
    showErrorMessage(e)
    yield put(ArtifactsActions.updateArtifactFailure(getErrorMessage(e)))
  }
}
// Create artifact with provided options
export function * createArtifact ({ projectId, name, artifactType, properties, missionId }) {
  yield put(ArtifactsActions.createArtifactLoading())
  try {
    const url = `/projects/${projectId}/artifacts`
    const body = {
      name,
      artifact_type: artifactType,
      properties: properties || {},
      mission_id: missionId,
    }
    const { data: { data: artifact } } = yield call(axios.post, url, body)
    yield put(ArtifactsActions.createArtifactSuccess(artifact))
  } catch (e) {
    yield put(ArtifactsActions.createArtifactFailure(getErrorMessage(e)))
    throw new Error(e)
  }
}
/**
 * Update artifact when data file from this artifact has been deleted
 * @param {Object} dataFile
 * @param {Object} artifact
 */
function * updateArtifactOnDeleteDataFile (dataFile, artifact) {
  // For now only Pointcloud artifacts properties should be updated
  if (isPointcloudArtifact(artifact.artifactType)) {
    const classifiedPointcloud = (path(['properties', 'files', 'classified_pointcloud'], artifact) || [])
      .filter(file => replaceMultipleWhiteSpaces(file) !== dataFile.fileName)
    const fileNames = (path(['properties', 'fileNames'], artifact) || [])
      .filter(file => replaceMultipleWhiteSpaces(file) !== dataFile.fileName)
    const newArtifact = set(
      lensPath(['properties', 'files', 'classified_pointcloud']),
      classifiedPointcloud,
      set(
        lensPath(['properties', 'fileNames']),
        fileNames,
        dissocPath(['properties', 'fileProperties', dataFile.fileName], artifact),
      ),
    )
    yield put(ArtifactsActions.updateArtifact(artifact.id, undefined, newArtifact.properties))
  }
}
// Delete individual data files
export function * deleteDataFile ({ dataFileId, dataFile, artifact }) {
  yield put(ArtifactsActions.deleteDataFileLoading())
  try {
    yield call(axios.delete, `/data_files/${dataFileId}`)
    yield put(ArtifactsActions.deleteDataFileSuccess(dataFileId))
    yield call(updateArtifactOnDeleteDataFile, dataFile, artifact)
  } catch (e) {
    yield put(ArtifactsActions.deleteDataFileFailure(getErrorMessage(e)))
  }
}
// Set dataDirectory to completed
export function * setCompleteDataDirectory ({ dataDirectoryId, completed }) {
  yield put(ArtifactsActions.setCompleteDataDirectoryLoading(dataDirectoryId))
  try {
    const url = `/data_directories/${dataDirectoryId}`
    const body = { completed }
    const { data: { data: dataDirectory } } = yield call(axios.post, url, body)
    yield put(ArtifactsActions.setCompleteDataDirectorySuccess(
      dataDirectoryId,
      convertRawDataDirectory(dataDirectory)),
    )
  } catch (e) {
    yield put(ArtifactsActions.setCompleteDataDirectoryFailure(dataDirectoryId, getErrorMessage(e)))
  }
}
// Delete file from dataDirectory (Camera artifact)
export function * deleteDataDirectoryFile ({ artifactId, dataDirectoryId, link }) {
  yield put(ArtifactsActions.deleteDataDirectoryFileLoading(link))
  try {
    yield call(axios.put, `/data_directories/${dataDirectoryId}/files`, { s3_key: link })
    yield put(ArtifactsActions.deleteDataDirectoryFileSuccess(artifactId, dataDirectoryId, link))
  } catch (e) {
    yield put(ArtifactsActions.deleteDataDirectoryFileFailure(link, getErrorMessage(e)))
  }
}
// Download all files in artifact as ZIP
export function * downloadArtifact ({ artifactId, dataDirectoryId }) {
  try {
    const token = yield call(getArtifactDownloadToken, artifactId)
    // const token = yield select(state => getToken(state))
    const anchor = document.createElement('a')
    // yield call(axios.get, `/artifacts/${artifactId}/download?token=${token}${dataDirectoryId ? `&data_directory_id${dataDirectoryId}` : ''}&check=true`)
    anchor.href = getArtifactDownloadLink(token)
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (e) {
    showErrorMessage(e)
  }
}
// Download selected dataDirectory files as ZIP
export function * downloadDataDirectoryFiles ({ dataDirectoryId, urls }) {
  try {
    const token = yield select(state => getToken(state))
    const anchor = document.createElement('a')
    const uriEncodedFileUrls = urls.map(fileUrl => encodeURI(fileUrl)).join(',')
    anchor.href = `${config.API_BASE}/data_directories/${dataDirectoryId}/download?token=${token}&files=${uriEncodedFileUrls}`
    anchor.target = '_self'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (e) {
    showErrorMessage(e)
  }
}
// Update current project artifacts statuses
export function * updateProjectArtifactsState ({ projectId, fullReplace }) {
  try {
    const [currentProject] = yield select(state => [getCurrentProject(state)])
    const projectArtifacts = currentProject.artifacts
    // const projId = projectId || select(state => getCurrentProject(state))
    let finalArtifacts = []
    let replace = fullReplace || false
    if (replace) {
      const url = `/projects/${projectId}/artifacts`
      const { data: { data: fullArtifacts } } = yield call(axios.get, url)
      finalArtifacts = fullArtifacts
      yield put(ArtifactsActions.updateProjectArtifactsStateSuccess(finalArtifacts, replace, projectId))
    } else {
      // properties, mission,
      const url = `/projects/${projectId}/artifacts?exclude=analyzing_pipeline,scheduled,is_output_artifact`
      const { data: { data: artifacts } } = yield call(axios.get, url)
      finalArtifacts = artifacts
      // If we get another array from backend we need to fully refresh the list
      if (projectArtifacts.length !== artifacts.length) {
        const url = `/projects/${projectId}/artifacts`
        const { data: { data: fullArtifacts } } = yield call(axios.get, url)
        finalArtifacts = fullArtifacts
        replace = true
      }
      yield put(ArtifactsActions.updateProjectArtifactsStateSuccess(finalArtifacts, replace, projectId))
    }
  } catch (e) {
    yield put(ArtifactsActions.updateProjectArtifactsStateFailure(projectId, getErrorMessage(e)))
  }
}

/*
export function * updateArtifactsStates ({ artifactIds }) {
  try {
    const token = yield select(getToken)
    // const projId = projectId || select(state => getCurrentProject(state))
    const url = `/projects/${projectId}/artifacts?exclude=analyzing_pipeline,mission,properties,scheduled,is_output_artifact`
    const { data: { data: artifacts }} = yield call(axios.get, url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    yield put(ArtifactsActions.updateProjectArtifactsStateSuccess(artifacts))
  } catch (e) {
    yield put(ArtifactsActions.updateProjectArtifactsStateFailure(e.response.data.message || e.message || e))
  }
}

*/
// Copying artifact into another project (both with files)
function * copyArtifact ({ projectId, oldProjectId, artifactId, artifact, onSuccess }) {
  yield put(ArtifactsActions.copyArtifactLoading())
  try {
    const { data: { data: newArtifact } } = yield call(axios.post, `/artifacts/${artifactId}/copy`, { project_id: projectId })
    const { data: { data: project } } = yield call(axios.get, `/projects/${oldProjectId}`)
    const gcps = project.gcps || {}
    yield put(ProjectsActions.updateProject(projectId, { gcps: { [newArtifact.id]: (gcps[artifactId] || gcps[newArtifact.name] || []) } }))
    toast.success(i18n.t('toast.artifact.copySuccess'))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    yield put(ArtifactsActions.copyArtifactSuccess(newArtifact))
  } catch (e) {
    yield put(ArtifactsActions.copyArtifactFailure(getErrorMessage(e)))
  }
}

// Export calibration to licensing server
export function * exportCalibration ({ artifactId, serial, data, successCallback }) {
  yield put(ArtifactsActions.exportCalibrationLoading(artifactId))
  try {
    const { data: { message } } = yield call(axiosAccountsClient.post, `/rovers/${serial}/import/`, data)
    yield put(ArtifactsActions.exportCalibrationSuccess(artifactId))
    if (typeof successCallback === 'function') {
      successCallback()
    }
    if (message) {
      toast.success(message)
    }
    // toast.success(i18n.t('toast.artifact.exportCalibrationSuccess'))
  } catch (e) {
    // showErrorMessage(e)
    const errorMessage = path(['response', 'data', 'message'], e)
    const errors = path(['response', 'data', 'errors'], e) || []
    yield put(ArtifactsActions.exportCalibrationFailure(artifactId, [errorMessage, ...errors].filter(Boolean)))
  }
}

export function * validateCalibration ({ artifactId, serial, data, successCallback }) {
  yield put(ArtifactsActions.validateCalibrationLoading(artifactId))
  try {
    const { data: { message, warnings } } = yield call(axiosAccountsClient.post, `/rovers/${serial}/import/?validate=true`, data)
    yield put(ArtifactsActions.validateCalibrationSuccess(artifactId, warnings))
    if (message) {
      toast.success(message)
    }
    if (typeof successCallback === 'function') {
      successCallback()
    }
    // toast.success(i18n.t('toast.artifact.exportCalibrationSuccess'))
  } catch (e) {
    // showErrorMessage(e)
    const errorMessage = path(['response', 'data', 'message'], e)
    const errors = path(['response', 'data', 'errors'], e) || []
    const warnings = path(['response', 'data', 'warnings'], e)
    yield put(ArtifactsActions.validateCalibrationFailure(artifactId, [errorMessage, ...errors].filter(Boolean), warnings))
  }
}

function * setPointcloudSettings ({ artifactId, fieldsMapping, crsValues, lasSettings, shouldRestart, onSuccess }) {
  yield put(ArtifactsActions.setPointcloudSettingsLoading())
  try {
    const { data: { data: artifact } } = yield call(axios.get, `/artifacts/${artifactId}`)
    const state = yield select(state => state)
    const { analyzing_pipeline } = artifact
    const [analyzingPipeline] = analyzing_pipeline
    if (shouldRestart) {
      yield call(retryPipeline, analyzingPipeline)
    }
    const fileProperties = path(['properties', 'fileProperties'], artifact) || {}
    const filePropertiesKeys = Object.keys(fileProperties)
    const fileNames = path(['properties', 'fileNames'], artifact) || []
    const allFileNames = makeUnique([...filePropertiesKeys, ...fileNames])
    const newArtifactProperties = getArtifactProperties({
      ...artifact,
      properties: {
        ...(path(['properties'], artifact) || {}),
        fileProperties: {
          ...fileProperties,
          ...allFileNames
            .filter(fileName => isLasFileName(fileName))
            .reduce((all, fileName) => ({
              ...all,
              [fileName]: {
                ...fileProperties[fileName],
                crs: crsValues,
              },
            }), {}),
        },
        las_settings: {
          ...lasSettings,
          fields_mapping: fieldsMapping,
        },
      },
    }, state)
    const { data: { data: newArtifact } } = yield call(axios.post, `/artifacts/${artifactId}`, { properties: newArtifactProperties })
    yield put(ArtifactsActions.updateArtifactSuccess(newArtifact.id, newArtifact))
    yield put(ArtifactsActions.setPointcloudSettingsSuccess(newArtifact))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success('Cloud Viewer settings has been successfully updated!')
  } catch (e) {
    showErrorMessage(e)
    yield put(ArtifactsActions.setPointcloudSettingsFailure(getErrorMessage(e)))
  }
}

function * fixAntennaMismatch ({ artifactId, data = {}, onSuccess }) {
  yield put(ArtifactsActions.fixAntennaMismatchLoading())
  try {
    const { data: { data: artifact } } = yield call(axios.get, `/artifacts/${artifactId}`)
    const body = {
      properties: {
        ...artifact.properties,
        antenna_mismatch: false,
        warnings: [],
        ...data,
      },
    }
    const { data: { data: newArtifact } } = yield call(axios.post, `/artifacts/${artifactId}`, body)
    const { analyzing_pipeline } = newArtifact
    const [analyzingPipeline] = analyzing_pipeline
    yield call(retryPipeline, analyzingPipeline)
    yield put(ArtifactsActions.updateArtifactSuccess(artifactId, newArtifact))
    yield put(ArtifactsActions.fixAntennaMismatchSuccess(artifactId))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    toast.success('Antenna mismatch has been fixed!')
  } catch (e) {
    showErrorMessage(e)
    yield put(ArtifactsActions.fixAntennaMismatchFailure(getErrorMessage(e)))
  }
}

function * updateArtifactPoses ({ artifactId }) {
  yield put(ArtifactsActions.updateArtifactPosesLoading(artifactId))
  try {
    const { data: { data: artifact } } = yield call(axios.get, `/artifacts/${artifactId}`)
    const { analyzing_pipeline, output_pipelines } = artifact
    if (artifact.is_output_artifact) {
      const firstNavLabPipeline = output_pipelines.find(pipeline => pipeline.job_types.find(jobType => isNavLabJob(jobType)))
      yield call(retryPipeline, firstNavLabPipeline, true)
      toast.success('NavLab pipeline has been restarted!')
    } else {
      const [firstAnalyzingPipeline] = analyzing_pipeline
      yield call(retryPipeline, firstAnalyzingPipeline)
      toast.success('Analyzing pipeline has been restarted!')
    }
    yield put(ArtifactsActions.updateArtifactPosesSuccess(artifactId))
  } catch (e) {
    showErrorMessage(e)
    yield put(ArtifactsActions.updateArtifactPosesFailure(artifactId, getErrorMessage(e)))
  }
}

function * updateProjectArtifactsStateWatcher () {
  yield takeLatest(ArtifactsActionTypes.UPDATE_PROJECT_ARTIFACTS_STATE, updateProjectArtifactsState)
}

function * getArtifactWatcher () {
  yield takeEvery(ArtifactsActionTypes.GET_ARTIFACT, getArtifact)
}

function * getArtifactsWatcher () {
  yield takeEvery(ArtifactsActionTypes.GET_ARTIFACTS, getArtifacts)
}

function * deleteArtifactWatcher () {
  yield takeLatest(ArtifactsActionTypes.DELETE_ARTIFACT, deleteArtifact)
}

function * createArtifactWatcher () {
  yield takeLatest(ArtifactsActionTypes.CREATE_ARTIFACT, createArtifact)
}

function * updateArtifactWatcher () {
  yield takeEvery(ArtifactsActionTypes.UPDATE_ARTIFACT, updateArtifact)
}

function * downloadArtifactWatcher () {
  yield takeLatest(ArtifactsActionTypes.DOWNLOAD_ARTIFACT, downloadArtifact)
}

function * downloadDataDirectoryFilesWatcher () {
  yield takeLatest(ArtifactsActionTypes.DOWNLOAD_DATA_DIRECTORY_FILES, downloadDataDirectoryFiles)
}

function * deleteDataFileWatcher () {
  yield takeLatest(ArtifactsActionTypes.DELETE_DATA_FILE, deleteDataFile)
}

function * deleteDataDirectoryFileWatcher () {
  yield takeLatest(ArtifactsActionTypes.DELETE_DATA_DIRECTORY_FILE, deleteDataDirectoryFile)
}

function * setCompleteDataDirectoryWatcher () {
  yield takeLatest(ArtifactsActionTypes.SET_COMPLETE_DATA_DIRECTORY, setCompleteDataDirectory)
}

function * copyArtifactWatcher () {
  yield takeLatest(ArtifactsActionTypes.COPY_ARTIFACT, copyArtifact)
}

function * exportCalibrationWatcher () {
  yield takeLatest(ArtifactsActionTypes.EXPORT_CALIBRATION, exportCalibration)
}
function * validateCalibrationWatcher () {
  yield takeLatest(ArtifactsActionTypes.VALIDATE_CALIBRATION, validateCalibration)
}
function * setPointcloudSettingsWatcher () {
  yield takeLatest(ArtifactsActionTypes.SET_POINTCLOUD_SETTINGS, setPointcloudSettings)
}
function * fixAntennaMismatchWatcher () {
  yield takeLatest(ArtifactsActionTypes.FIX_ANTENNA_MISMATCH, fixAntennaMismatch)
}
function * updateArtifactPosesWatcher () {
  yield takeEvery(ArtifactsActionTypes.UPDATE_ARTIFACT_POSES, updateArtifactPoses)
}

/*
function * getCurrentArtifactDataWatcher () {
  yield takeLatest(ArtifactsActionTypes.GET_CURRENT_ARTIFACT_DATA, getCurrentArtifactData)
}
*/

export default function * root () {
  yield fork(getArtifactWatcher)
  yield fork(getArtifactsWatcher)
  // yield fork(getCurrentArtifactDataWatcher)
  yield fork(updateProjectArtifactsStateWatcher)
  yield fork(downloadArtifactWatcher)
  yield fork(downloadDataDirectoryFilesWatcher)
  yield fork(deleteArtifactWatcher)
  yield fork(createArtifactWatcher)
  yield fork(updateArtifactWatcher)
  yield fork(deleteDataFileWatcher)
  yield fork(deleteDataDirectoryFileWatcher)
  yield fork(setCompleteDataDirectoryWatcher)
  yield fork(copyArtifactWatcher)
  yield fork(exportCalibrationWatcher)
  yield fork(validateCalibrationWatcher)
  yield fork(setPointcloudSettingsWatcher)
  yield fork(fixAntennaMismatchWatcher)
  yield fork(updateArtifactPosesWatcher)
}
