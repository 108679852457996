/**
 * This module exports types for defining input forms for job options.
 */

/**
 * A List of different data types.
 */
export const DataType = {
  BOOLEAN: 0,
  STRING: 1,
  INTEGER: 2,
  FLOAT: 3,
  SELECTION: 4,
  VECTOR3: 5,
  COORDINATE: 6,
  COORDINATE_SYSTEMS: 7,
  AUTOCOMPLETE: 8,
  DATETIME: 9,
  CESIUM_MAP: 10,
  TABS: 11,
  CUSTOM_CRS: 12,
  TABS_TEMPLATE: 13,
  LAST_CHANGED_TAB: 14,
  IMAGE: 15,
  GEOID_AUTOCOMPLETE: 16,
  TEXT: 17,
  REF_STATION_POSITION: 18,
  REF_STATION_POSITION_TRANSFER: 19,
  BUTTON: 20,
  GPS_WEEK: 21,
  MULTI_FLOAT: 22,
  DROPDOWN_PANEL: 23,
  FILELIST_SELECTOR: 24,
  CRS: 25,
  TIME_OF_WEEK: 26,
  SLIDER: 27,
  MULTI_SELECTION: 28,
  ANTENNA_AUTOCOMPLETE: 29,
  TRAJECTORY_AUTOSPLIT: 30,
}
