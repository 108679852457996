import { createReducer } from 'reduxsauce'
import { omit, path } from 'ramda'
import { v4 } from 'uuid'
// Project deps
import { removeById, makeUniqueById, mapById, findById } from 'utils/list'
import { ArtifactsTypes } from 'modules/artifacts/actions'
import { ProjectsTypes } from 'modules/projects/actions'
import { isDropboxFile } from 'modules/importWizard/utils'
import { getIdealChunkSize } from 'modules/upload/config'
// Local deps
import { INITIAL_STATE } from './initialState'
import {
  UploadStatus,
  CurrentUploadStage,
  getFailedDataDirectoryFileForFile,
  fileShouldBeAddedToFailedFiles,
} from './utils'
import { UploadTypes } from './actions'
import { isCamFileName, isImageFileType, isPfsFileName, isPlpFileName } from './file-types'
import { LidarmillSentry } from 'config'
import { getFilesForArtifact, getFilesForProject } from 'utils/upload'
import { ArtifactStatuses } from 'types/artifacts'

export const startUpload = (state, action) => {
  const { artifactId, autocomplete: autoComplete, dataDirectoryId } = action
  const currentArtifact = state.get('currentArtifact')
  return state.merge({
    // ... set the upload stage of that artifact to `RUNNING`.
    // It means that files for this artifact ready to be used (to be prepared and to be uploaded)
    currentArtifact: {
      ...currentArtifact,
      [artifactId]: {
        stage: CurrentUploadStage.RUNNING,
        autoComplete,
        dataDirectoryId,
      },
    },
  })
}

function clearOnBeforeUnloadListener () {
  window.onbeforeunload = null
}

export const startDropboxUpload = (state, action) => {
  const { artifactId } = action
  const files = state.get('files')
  return state.merge({
    // Removing all the dropbox files with artifactId from uploader state
    files: files.filter(file => file.artifactId !== artifactId && !isDropboxFile(file)),
  })
}

export const addFiles = (state, action) => {
  const { artifactId, files: fileList, projectId = '' } = action
  // Create `UploadingFile`s from the native `File`s.
  const files = fileList.map(file =>
    ({
      file: (file instanceof File ? file : file.file),
      artifactId,
      status: UploadStatus.PENDING,
      projectId,
      fileType: file.fileType,
      ...(typeof file.replace === 'string' && { replace: file.replace }),
      id: file.id || v4(),
    }),
  )
  const currentArtifact = state.get('currentArtifact')
  const newFiles = [...state.get('files'), ...files]
  // const otherFiles = newFiles.filter(file => !isImageFileType(file.fileType))
  // const images = newFiles.filter(file => isImageFileType(file.fileType))
  return state.merge({
    // Set the stage to `ADDING_FILES` for the specified artifact.
    currentArtifact: {
      ...currentArtifact,
      [artifactId]: {
        ...currentArtifact[artifactId],
        stage: CurrentUploadStage.ADDING_FILES,
      },
    },
    // Add the files to the list of files.
    files: newFiles,
  })
}

const fileAtTheTop = ({ file: { name } }) => (
  isCamFileName(name) ||
  isPfsFileName(name) ||
  isPlpFileName(name)
)
/**
 * Always move the .plp, .cam, .pfs files to the top of the uploader queue to upload them first
 * After that all other files
 * @param {Object} a File a
 * @param {Object} b File b
 */
const filesSort = (a, b) => {
  return fileAtTheTop(b)
    ? 1
    : a.file.size - b.file.size
}

export const addDataFiles = (state, action) => {
  const { files } = action
  // Create `UploadingFile`s from the native `File`s.
  return state.merge({
    dataFiles: makeUniqueById([
      ...files,
      ...state.get('dataFiles'),
    ]),
  })
}

// If a user demands that all pending files for a specified artifact should be removed, ...
export const removeAllFiles = (state, action) => {
  const { artifactId } = action
  const currentArtifact = state.get('currentArtifact')
  const newFiles = state.get('files').filter(uploadFile =>
    uploadFile.status !== UploadStatus.PENDING || uploadFile.artifactId !== artifactId,
  )
  return state.merge({
    // ... remove all files which belong to that artifact and are `PENDING`.
    files: newFiles,
    currentArtifact: {
      ...currentArtifact,
      [artifactId]: {
        ...currentArtifact[artifactId],
        stage: getFilesForArtifact(newFiles, artifactId).length > 0
          ? path([artifactId, 'stage'], currentArtifact) || CurrentUploadStage.UNTOUCHED
          : CurrentUploadStage.UNTOUCHED,
      },
    },
  })
}

// If the user wants to remove a specific pending file ...
export const removeFile = (state, action) => {
  const { file } = action
  return state.merge({
    // ... keep all files but this one.
    files: state.get('files').filter(uploadFile => uploadFile.file !== file),
  })
}

export const cancel = (state, action) => {
  const { artifactId } = action
  const transfer = state.get('transfer')
  const currentArtifact = state.get('currentArtifact')
  const files = state.get('files')
  // const currentlyUploadingFile = getCurrentlyUploadingFile({ files })
  const newFiles = files.filter(uploadFile => {
    const fileInTransfer = transfer.find(transferState => (
      transferState.id === uploadFile.id &&
      uploadFile.artifactId === artifactId
    ))
    if (fileInTransfer) {
      if (fileInTransfer.emitter) {
        fileInTransfer.emitter.emit('cancel')
      }
      return false
    }
    return uploadFile.artifactId !== artifactId
  })
  if (newFiles.length <= 0) {
    clearOnBeforeUnloadListener()
  }
  return state.merge({
    // ... set the status of the specified upload to cancelled ...
    currentArtifact: {
      ...currentArtifact,
      [artifactId]: {
        ...currentArtifact[artifactId],
        cancelled: true,
      },
    },
    // ... and remove all files of that upload except for the currently uploading one.
    files: newFiles,
    busy: !(newFiles.length <= 0),
  })
}

export const setBusy = (state, { busy }) => {
  if (busy) {
    if (!window.onbeforeunload) {
      window.onbeforeunload = function (event) {
        event.preventDefault()
        event.returnValue = 'true'
        LidarmillSentry.captureMessage(
          'Attempt to cancel the uploading: ' + Object.keys(state.get('currentArtifact')).join(', '),
          LidarmillSentry.Severity.Warning,
        )
        return true
      }
    }
  } else {
    if (window.onbeforeunload) {
      clearOnBeforeUnloadListener()
    }
  }
  return state.merge({ busy })
}

export const prefixFiles = (state, { s3KeyPrefix, files }) => {
  return state.merge({
    // Append the S3 key prefix to all corresponding files.
    files: state.get('files').map(uploadFile => files.indexOf(uploadFile.file) !== -1
      ? {
        ...uploadFile,
        s3KeyPrefix,
        // ...(uploadFile.replace ? { replace: s3KeyPrefix + uploadFile.replace} : {})
      }
      : uploadFile,
    ),
  })
}

export const prepareFilesDone = (state, action) => {
  const { artifactId, files: preparedFiles } = action
  if (preparedFiles && preparedFiles.length > 0) {
    const preparedFilesByArtifact = state.get('preparedFilesByArtifact')
    preparedFilesByArtifact[artifactId] = preparedFiles
    return state.merge({
      preparedFilesByArtifact,
    })
  }
  const files = state.get('files').map(uploadFile => {
    if (uploadFile.artifactId !== artifactId) {
      return uploadFile
    }
    return {
      ...uploadFile,
      status: UploadStatus.PREPARED,
    }
  })
  return state.merge({
    files,
  })
}

export const prepareFileDone = (state, action) => {
  const { id, dataFileId, chunks, s3KeyPrefix, dataFile, artifactId } = action
  const prepare = state.get('prepare')
  const files = state.get('files')
  const dataFiles = state.get('dataFiles')
  const fileToUpdateIndex = files.findIndex(f => f.id === id)
  if (fileToUpdateIndex >= 0) {
    files[fileToUpdateIndex].status = UploadStatus.PREPARED
    files[fileToUpdateIndex].dataFileId = dataFileId
    files[fileToUpdateIndex].chunks = chunks
    files[fileToUpdateIndex].s3KeyPrefix = s3KeyPrefix
    files[fileToUpdateIndex].lastUploadedChunkIndex = chunks ? chunks.findIndex(chunk => !chunk.completed) : 0
  }
  const dataFileToUpdateIndex = dataFiles.findIndex(f => f.id === dataFileId)
  if (dataFileToUpdateIndex >= 0) {
    dataFiles[dataFileToUpdateIndex] = dataFile
  }
  /*
  files = files.map(uploadFile => {
    // const currentlyUploading = getCurrentlyUploadingFile({ ...state, files })
    const currentlyUploadingFile = transfer.find(transferState => transferState.file === uploadFile.file)
    // If this is the currently uploading file ...
    if (Boolean(currentlyUploadingFile)) {
      // ... set the started time.
      return { ...uploadFile, started: new Date() }
    }
    return uploadFile
  })
  */
  const preparedFilesByArtifact = state.get('preparedFilesByArtifact')
  if (preparedFilesByArtifact[artifactId]) {
    preparedFilesByArtifact[artifactId].push({ id })
  } else {
    preparedFilesByArtifact[artifactId] = [{ id }]
  }
  return state.merge({
    files,
    dataFiles,
    // failedFiles: removeById(dataFileId, state.get('failedFiles')),
    // Reset progress to 0 when done.
    prepare: removeById(id, prepare),
    preparedFilesByArtifact,
  })
}

export const prepareFileProgress = (state, action) => {
  const { progress, id } = action
  const prepare = state.get('prepare')
  return state.merge({
    // ... update the progress in the prepare state.
    prepare: mapById(id, prepare, prepareState => ({ ...prepareState, progress })),
  })
}

export const prepareFileError = (state, action) => {
  const { error, id } = action
  const prepare = state.get('prepare')
  return state.merge({
    // ... update the error in the prepare state.
    prepare: mapById(id, prepare, prepareState => ({ ...prepareState, error })),
  })
}

export const dataFileError = (state, action) => {
  const { id } = action
  return state.merge({
    files: removeById(id, state.get('files')),
    prepare: removeById(id, state.get('prepare')),
    transfer: removeById(id, state.get('transfer')),
  })
}

export const allFilesDone = (state, action) => {
  const { artifactId } = action
  const filesForArtifact = getFilesForArtifact(state.get('files'), artifactId)
  return state.merge({
    preparedFilesByArtifact: omit([artifactId], state.get('preparedFilesByArtifact')),
    uploadArtifactState: omit([artifactId], state.get('uploadArtifactState')),
    // ... remove the files associated with the finished artifact from the state.
    files: state.get('files').filter(uploadFile => uploadFile.artifactId !== artifactId),
    transfer: state.get('transfer').filter(transferState => !filesForArtifact.some(file => file.id === transferState.id)),
    // ... Remove the artifact from the `currentArtifact` state.
    currentArtifact: omit([artifactId], state.get('currentArtifact')),
  })
}

export const setCancelFunction = (state, action) => {
  const { id, userEmitter } = action
  const transfer = state.get('transfer')
  return state.merge({
    transfer: mapById(id, transfer, transferState => ({ ...transferState, emitter: userEmitter })),
  })
}

export const setArtifactUpdateStatus = (state, action) => {
  const { id, artifactId } = action
  const currentArtifactState = state.get('currentArtifact')
  return state.merge({
    currentArtifact: {
      ...currentArtifactState,
      [artifactId]: {
        ...currentArtifactState[artifactId],
        fileId: id,
      },
    },
  })
}

export const startTransfer = (state, action) => {
  const { id, artifactId } = action
  const currentArtifactState = state.get('currentArtifact')
  const files = state.get('files')
  const fileToUpdateIndex = files.findIndex(f => f.id === id)
  files[fileToUpdateIndex].started = new Date()
  files[fileToUpdateIndex].duration = 0
  return state.merge({
    files,
    currentArtifact: {
      ...currentArtifactState,
      [artifactId]: {
        ...currentArtifactState[artifactId],
        fileId: id,
      },
    },
    transfer: [
      ...state.get('transfer'),
      {
        progress: 0,
        errorOccured: false,
        total: 0,
        done: 0,
        failed: 0,
        tries: 0,
        id,
        artifactId,
      },
    ],
  })
}

export const startPrepare = (state, action) => {
  const { id } = action
  return state.merge({
    prepare: [
      ...state.get('prepare'),
      { id, progress: 0 },
    ],
  })
}

export const chunkUploaded = (state, action) => {
  const { done, total, failed, id } = action.progress
  const files = state.get('files')
  const transfer = state.get('transfer')
  const currentlyUploadingFile = findById(id, files) // getCurrentlyUploadingFile({ files })
  let chunksUploaded = 0
  const newTransfer = mapById(id, transfer, transferState => {
    // Remove the current chunk from the transfer state.
    chunksUploaded = done - transferState.done
    return omit(['currentChunk', 'firstFailureInCurrentSeries'], {
      ...transferState,
      progress: done / total,
      done,
      total,
      failed,
      tries: 0,
    })
  })
  const chunks = currentlyUploadingFile && currentlyUploadingFile.chunks
  let lastUploadedChunkIndex = currentlyUploadingFile && currentlyUploadingFile.lastUploadedChunkIndex

  if (chunks) {
    if (lastUploadedChunkIndex < 0 || typeof lastUploadedChunkIndex === 'undefined') {
      chunks[0] = true
      lastUploadedChunkIndex = 0
    } else {
      // const chunk = { ...chunks[lastUploadedChunkIndex] }
      // chunks[lastUploadedChunkIndex] = true
      for (let i = lastUploadedChunkIndex; i < lastUploadedChunkIndex + chunksUploaded; i++) chunks[i] = true
      lastUploadedChunkIndex = lastUploadedChunkIndex + chunksUploaded
    }
    const fileToUpdateIndex = files.findIndex(f => f.id === currentlyUploadingFile.id)
    if (fileToUpdateIndex >= 0) {
      files[fileToUpdateIndex].chunks = chunks
      files[fileToUpdateIndex].lastUploadedChunkIndex = lastUploadedChunkIndex
    }
    return state.merge({
      files,
      transfer: newTransfer,
    })
  } else {
    return state.merge({ transfer: newTransfer })
  }
}

export const done = (state, action) => {
  const { result: { id, dataFileId } } = action
  // TODO: Handle `okay === false`
  const files = state.get('files')
  const fileToUpdateIndex = files.findIndex(f => f.id === id)
  if (fileToUpdateIndex >= 0) {
    files[fileToUpdateIndex].status = UploadStatus.DONE
    files[fileToUpdateIndex].finished = new Date()
    files[fileToUpdateIndex].chunks = undefined
    files[fileToUpdateIndex].lastUploadedChunkIndex = undefined
  }
  // Remove any associated failed files from the list of failed files in order to prohibit the user from retrying
  // the file again. If an error occured during the upload the list of failed files should remain untouched.
  const transfer = state.get('transfer')
  const fileInTransferState = findById(id, transfer)
  const failedFiles = (fileInTransferState &&
    typeof fileInTransferState.errorOccured === 'boolean' && fileInTransferState.errorOccured
  )
    ? state.get('failedFiles')
    : removeById(dataFileId, state.get('failedFiles'))
  return state.merge({
    // ... set the status of the specified file to `DONE`, ...
    files,
    // ... reset all progress to 0 when done, ...
    transfer: removeById(id, transfer),
    // ... and remove the failed files from the list of failed files.
    failedFiles,
  })
}

export const updateUploadRates = (state, action) => {
  const { id, duration } = action
  // TODO: Handle `okay === false`
  const files = state.get('files')
  const fileToUpdateIndex = files.findIndex(f => f.id === id)
  if (fileToUpdateIndex >= 0) {
    files[fileToUpdateIndex].duration = duration
  }
  return state.merge({ files })
}

export const imageFailed = (state, { id }) => {
  const transfer = state.get('transfer')
  return state.merge({
    // ... reset all progress to 0 when done, ...
    transfer: removeById(id, transfer),
  })
}

export const chunkProgress = (state, action) => {
  const { progress, chunk, id } = action
  return state.merge({
    // Update the current upload progress.
    transfer: mapById(id, state.get('transfer'), transferState => ({
      ...transferState,
      progress,
      currentChunk: chunk,
    })),
  })
}

export const reset = state => state.merge({
  ...INITIAL_STATE.toObject(),
})

const getDataFiles = (state, { artifact, artifacts, dataFiles, dataDirectories = [] }) => {
  if (dataFiles.length === 0 && dataDirectories === 0) {
    return state.merge({})
  }
  const artifactsToFindDataFile = artifacts || (artifact ? [artifact] : [])
  const files = state.get('files')
  const dfiles = state.get('dataFiles')
  const failedFiles = state.get('failedFiles')
  const failedDataDirectories = state.get('failedDataDirectories')
  const currentArtifactState = state.get('currentArtifact')
  const newDataFiles = makeUniqueById([
    // ...dataFiles.filter(dataFile => !dataFile.completed),
    // If uploader already running we should not replace data files
    // Because it will overwrite our chunks progression
    ...dataFiles.filter(df => {
      const artifactAdded = currentArtifactState[df.artifactId]
      return !artifactAdded || (artifactAdded && artifactAdded.stage !== CurrentUploadStage.RUNNING)
    }),
    ...dfiles,
  ])
  const newFailedDataDirectories = makeUniqueById([
    ...dataDirectories.filter(dataDirectory => !dataDirectory.completed),
    ...failedDataDirectories,
  ])
  const calculateCompletedSize = failedFile => {
    const chunkSize = getIdealChunkSize(failedFile.size)
    return failedFile.chunks ? failedFile.chunks.reduce((result, chunk) => typeof chunk === 'object'
      ? chunk.completed ? result + chunk.size : result
      : chunk ? result + chunkSize : result
    , 0) : 0
  }
  const newFailedFiles = failedFiles
    .filter(failedFile =>
      newDataFiles.find(dataFile =>
        dataFile.id !== failedFile.id ||
        (dataFile.id === failedFile.id && !dataFile.completed),
      ),
    )
  const newFailedFiles_ = [
    ...newFailedFiles,
    ...newDataFiles.filter(dataFile =>
      fileShouldBeAddedToFailedFiles(dataFile, files, newFailedFiles, artifactsToFindDataFile),
    ).map(failedFile => ({
      ...failedFile,
      completedSize: calculateCompletedSize(failedFile),
    })),
  ]
  return state.merge({
    dataFiles: newDataFiles,
    failedFiles: newFailedFiles_,
    failedDataDirectories: newFailedDataDirectories,
  })
}

/*
const getDataDirectories = (state, { dataDirectories }) => {
  // If the `DataDirectory` was not completed, this means the upload failed and needs to be kept in the state.
  // Otherwise ignore the file.
  return state.merge({
    // Add the directory to the list of failed directories.
    failedDataDirectories: [
      ...state.failedDataDirectories,
      ...dataDirectories.filter(dataDirectory => !dataDirectory.completed),
    ],
  })
}
*/

export const deleteDataFileSuccess = (state, { dataFileId }) => {
  return state.merge({
    dataFiles: removeById(dataFileId, state.get('dataFiles')),
    failedFiles: removeById(dataFileId, state.get('failedFiles')),
  })
}

export const deleteProjectsSuccess = (state, { projectIds }) => {
  let files = state.get('files')
  const transfer = state.get('transfer')
  projectIds.forEach(projectId => {
    const filesForProject = getFilesForProject(files, projectId)
    const uploadingFileForProject = transfer.filter(transferState => findById(transferState.id, filesForProject))
    uploadingFileForProject.forEach(uploadingFile => {
      if (uploadingFile.emitter) {
        uploadingFile.emitter.emit('cancel')
      }
    })
    files = files.filter(file => file.projectId !== projectId)
  })
  return state.merge({ files })
}

export const setDataDirectoryUploadedFiles = (state, { dataDirectoryId, numberOfFiles }) => {
  return state.merge({
    dataDirectoryUploadedFiles: {
      ...state.get('dataDirectoryUploadedFiles'),
      [dataDirectoryId]: numberOfFiles,
    },
  })
}

export const uploadFileError = (state, { id, artifactId, total, done, failed, file, dataFileId }) => {
  return state.merge({
    transfer: mapById(id, state.get('transfer'), transferState => ({
      ...transferState,
      tries: failed,
    })),
  })
}

// That is called when file is fully uploaded and completed
export const dataFileDone = (state, { id, dataFile }) => {
  const dataFiles = state.get('dataFiles')
  const dataFileToUpdateIndex = dataFiles.findIndex(f => f.id === id)
  if (dataFileToUpdateIndex >= 0) {
    dataFiles[dataFileToUpdateIndex] = { ...dataFile }
  }
  return state.merge({
    dataFiles,
  })
}

// That is called when file is fully uploaded and completed
export const dataFileChunkDone = (state, { dataFileId, chunkId }) => {
  const dataFiles = state.get('dataFiles')
  const dataFileToUpdateIndex = dataFiles.findIndex(f => f.id === dataFileId)
  if (dataFileToUpdateIndex >= 0) {
    const chunkIndex = (dataFiles[dataFileToUpdateIndex].chunks || []).findIndex(chunk => chunk.id === chunkId)
    if (chunkIndex >= 0) {
      dataFiles[dataFileToUpdateIndex].chunks[chunkIndex].completed = true
    }
  }
  return state.merge({
    dataFiles,
  })
}

export const startUploadDataFile = (state, action) => {
  const { artifactId } = action
  return state.merge({
    uploadArtifactState: {
      [artifactId]: ArtifactStatuses.UPLOADING,
    },
  })
}

export const setTimer = (state, action) => {
  const { artifactId } = action
  return state.merge({
    timerSet: {
      ...state.get('timerSet'),
      [artifactId]: true,
    },
  })
}

export const reducer = createReducer(INITIAL_STATE, {
  [UploadTypes.START_UPLOAD]: startUpload,
  [UploadTypes.START_DROPBOX_UPLOAD]: startDropboxUpload,
  [UploadTypes.CANCEL]: cancel,
  [UploadTypes.REMOVE_ALL_FILES]: removeAllFiles,
  [UploadTypes.REMOVE_FILE]: removeFile,
  [UploadTypes.ADD_DATA_FILES]: addDataFiles,
  [UploadTypes.ADD_FILES]: addFiles,
  [UploadTypes.PREFIX_FILES]: prefixFiles,
  [UploadTypes.START_TRANSFER]: startTransfer,
  [UploadTypes.SET_CANCEL_FUNCTION]: setCancelFunction,
  [UploadTypes.START_PREPARE]: startPrepare,
  [UploadTypes.PREPARE_FILE_DONE]: prepareFileDone,
  [UploadTypes.PREPARE_FILE_PROGRESS]: prepareFileProgress,
  [UploadTypes.PREPARE_FILES_DONE]: prepareFilesDone,
  [UploadTypes.PREPARE_FILE_ERROR]: prepareFileError,
  [UploadTypes.DATA_FILE_ERROR]: dataFileError,
  [UploadTypes.ALL_FILES_DONE]: allFilesDone,
  [UploadTypes.CHUNK_UPLOADED]: chunkUploaded,
  [UploadTypes.UPLOAD_FILE_ERROR]: uploadFileError,
  [UploadTypes.DONE]: done,
  [UploadTypes.IMAGE_FAILED]: imageFailed,
  [UploadTypes.CHUNK_PROGRESS]: chunkProgress,
  [UploadTypes.RESET]: reset,
  [UploadTypes.SET_BUSY]: setBusy,
  [UploadTypes.SET_DATA_DIRECTORY_UPLOADED_FILES]: setDataDirectoryUploadedFiles,
  [UploadTypes.SET_ARTIFACT_UPDATE_STATUS]: setArtifactUpdateStatus,
  [UploadTypes.DATA_FILE_DONE]: dataFileDone,
  [UploadTypes.DATA_FILE_CHUNK_DONE]: dataFileChunkDone,
  [UploadTypes.START_UPLOAD_DATA_FILE]: startUploadDataFile,
  [UploadTypes.UPDATE_UPLOAD_RATES]: updateUploadRates,
  [UploadTypes.SET_TIMER]: setTimer,

  // [ArtifactDetailsTypes.GET_CURRENT_ARTIFACT_DATA_SUCCESS]: getDataFiles,
  [ArtifactsTypes.DELETE_DATA_FILE_SUCCESS]: deleteDataFileSuccess,
  [ProjectsTypes.GET_DATA_FOR_ARTIFACTS_SUCCESS]: getDataFiles,
  [ProjectsTypes.GET_DATA_FOR_ARTIFACT_SUCCESS]: getDataFiles,
  [ProjectsTypes.DELETE_PROJECTS_SUCCESS]: deleteProjectsSuccess,
})
