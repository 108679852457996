import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// Material UI
import { withStyles } from '@material-ui/core/styles'
import { InputAdornment, CircularProgress } from '@material-ui/core'
// Icons
import SearchIcon from '@material-ui/icons/Search'
// Project deps
import { token as getToken } from 'modules/users/selectors'
import DelayedSearchBox from 'components/DelayedSearchBox'
import RemoveIconButton from 'components/RemoveIconButton'
// Local deps
import SearchList from './SearchList'
import { apiSearch } from './utils'

const height = 42

const styles = theme => ({
  container: {
    width: '100%',
    height,
    overflow: 'hidden',
    boxShadow: theme.mixins.boxShadow,
  },
  input: {
    flex: 1,
    height,
    transition: 'all .2s ease',
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
  notchedOutline: {
    border: 'none',
  },
})

class UserSearch extends React.Component {
  responseSize = 1
  initialState = {
    search: '',
    open: true,
    data: [],
    hasMore: true,
    page: 2,
    scrollBy: 0,
    isLoading: false,
    total: 0,
  }
  constructor (props) {
    super(props)
    const { value } = props
    this.state = {
      ...this.initialState,
      search: value || '',
    }
    if (value) {
      this.handleSearch(value)
    }
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open, focused: true }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false })
  }

  handleClear = () => {
    const { onClear } = this.props
    this.setState(this.initialState)
    if (typeof onClear === 'function') {
      onClear()
    }
  }

  handleSearch = async value => {
    if (value) {
      const { userId, token } = this.props
      const result = await apiSearch(value, userId, token, 1, this.responseSize)
      const resultData = result.results
      const totalResults = result.total
      const resultLength = resultData.length
      if (resultLength) {
        this.setState({
          data: resultData,
          open: true,
          hasMore: !(resultLength < this.responseSize),
          total: totalResults,
        })
        this.loadAll()
      } else {
        this.setState({ data: [], open: true, hasMore: false, total: 0 })
      }
    } else {
      this.handleClear()
    }
  }

  handleSearchChange = value => {
    const { onChange } = this.props
    this.setState({ search: value })
    this.handleSearch(value)
    if (typeof onChange === 'function') {
      onChange(value)
    }
  }

  loadNext = scrollBy => {
    const { search, page, hasMore } = this.state
    const { userId, token } = this.props
    if (hasMore) {
      this.setState({ isLoading: true })
      setTimeout(async () => {
        const result = await apiSearch(search, userId, token, page, this.responseSize)
        const resultData = result.results
        const resultLength = resultData.length
        if (resultLength > 0) {
          this.setState(prevState => ({
            data: [...this.state.data, ...resultData],
            open: true,
            page: prevState.page + 1,
            hasMore: !(resultLength < this.responseSize),
            isLoading: false,
            scrollBy: scrollBy || 0,
          }))
        } else {
          this.setState({ hasMore: false, isLoading: false, open: true })
        }
      }, 500)
    }
  }

  loadAll = () => {
    const { search, hasMore, total } = this.state
    const { userId, token } = this.props
    if (hasMore) {
      this.setState({ isLoading: true })
      setTimeout(async () => {
        const result = await apiSearch(search, userId, token, 1, total)
        const resultData = result.results
        const resultLength = resultData.length
        if (resultLength > 0) {
          this.setState({
            data: resultData,
            open: true,
            page: 1,
            hasMore: false,
            isLoading: false,
          })
        } else {
          this.setState({ hasMore: false, isLoading: false })
        }
      }, 500)
    }
  }

  setSearchFieldRef = node => {
    this.anchorEl = node
  }

  render () {
    const { open, search } = this.state
    const { classes, placeholder = 'Search...', additionalControls, fullWidth, square, defaultType } = this.props
    const color = defaultType ? 'inherit' : '#fff'
    return (
      <div className={classes.container} style={{ borderRadius: square ? 0 : 4 }}>
        <DelayedSearchBox
          delay={500}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup='true'
          placeholder={placeholder}
          value={search}
          className={classes.input}
          inputRef={this.setSearchFieldRef}
          onChange={this.handleSearchChange}
          onBlur={() => {
            this.setState({ focused: false })
          }}
          onFocus={this.handleToggle}
          onMouseOver={() => this.setState({ hover: true }) }
          onMouseOut={() => this.setState({ hover: false }) }
          variant='outlined'
          fullWidth
          inputProps={defaultType ? {} : {
            style: {
              color,
            },
          }}
          InputProps={{
            classes: { root: classes.input, notchedOutline: classes.notchedOutline },
            style: defaultType ? { background: '#fff' } : {
              width: fullWidth ? '100%' : this.state.hover || this.state.focused || this.state.search ? 270 : 270,
              background: this.state.hover || this.state.focused ? '#3b4148' : '#32373d',
            },
            startAdornment: <InputAdornment position='start'>
              {this.state.isLoading ? <CircularProgress size={24}/> : <SearchIcon style={{ color }}/>}
            </InputAdornment>,
            endAdornment: <InputAdornment position='end'>
              <RemoveIconButton
                size='small'
                style={{ color }}
                aria-label='Clear'
                onClick={this.handleClear}
              />
              {typeof additionalControls === 'function' && additionalControls()}
            </InputAdornment>,
          }}
        />
        { search && (
          <SearchList
            handleClose={this.handleClose}
            anchorEl={this.anchorEl}
            loadNext={this.loadNext}
            loadAll={this.loadAll}
            {...this.state}
          />
        )}
      </div>
    )
  }
}

UserSearch.propTypes = {
  classes: PropTypes.object,
  userId: PropTypes.string,
  token: PropTypes.string,
  square: PropTypes.bool,
  defaultType: PropTypes.bool,
  value: PropTypes.string,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  additionalControls: PropTypes.func,
}

const mapStateToProps = state => ({
  token: getToken(state),
})

const ConnectedComponent = withStyles(styles)(connect(mapStateToProps)(withRouter(UserSearch)))

ConnectedComponent.defaultProps = {
  userId: '',
}

export default ConnectedComponent
