import axios from 'axios'
import config from 'config'
import { toast } from 'react-toastify'

/**
 * Asynchronously requests to set authentication cookies.
 * If the request fails, it displays an error toast notification.
 *
 * @returns {Promise<void>} A promise that resolves when the request is completed.
 */
export const setAuthCookies = async (token, permissionType) => {
  try {
    await axios.get(`${config.API_BASE}/set_auth_cookies?permission_type=${permissionType}`, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } catch (e) {
    toast.error(e.message)
  }
}