export const getCompanyUsersIsLoading = state => {
  return state.companies.get('getUsersIsLoading')
}

export const getCompanyUsers = state => {
  return state.companies.get('users')
}

export const getCompanyProjectsIsLoading = (state, companyId) => {
  return !!state.companies.get('getProjectsIsLoading')[companyId]
}

export const getCompanyProjects = (state, companyId) => {
  return state.companies.get('companyProjects')[companyId] || []
}

export const getCurrentCompanyProjects = state => {
  return state.companies.get('projects') || []
}

export const generateInviteUrlIsLoading = (state, companyId) => {
  return !!state.companies.get('generateInviteUrlIsLoading')[companyId]
}

export const getInviteUrl = (state, companyId) => {
  return state.companies.get('inviteUrl')[companyId]
}

export const getCurrentCompany = state => {
  return state.companies.get('currentCompany')
}

export const getCurrentCompanyIsLoading = state => {
  return state.companies.get('getCompanyIsLoading')
}

export const getCompanies = state => {
  return state.companies.get('companies')
}

export const getCompaniesIsLoading = state => {
  return state.companies.get('getCompaniesIsLoading')
}

export const updateCompanyIsLoading = state => {
  return state.companies.get('updateCompanyIsLoading')
}

export const deleteCompanyIsLoading = state => {
  return state.companies.get('deleteCompanyIsLoading')
}

export const getCompanySystemTypes = (state, companyId) => {
  const systemTypesByCompany = state.companies.get('systemTypesByCompany') || {}
  return (systemTypesByCompany[companyId] || []).filter(systemType => !systemType.deprecated)
}

export const isGetCompanySystemTypesLoading = (state, companyId) => {
  const systemTypesByCompanyLoading = state.companies.get('systemTypesByCompanyLoading') || {}
  return systemTypesByCompanyLoading[companyId] || false
}

export const getCompanySubscriptions = (state, companyId) => {
  const subscriptionsByCompany = state.companies.get('subscriptionsByCompany') || {}
  return subscriptionsByCompany[companyId] || []
}

export const isGetCompanySubscriptionsLoading = (state, companyId) => {
  const subscriptionsByCompanyLoading = state.companies.get('subscriptionsByCompanyLoading') || {}
  return subscriptionsByCompanyLoading[companyId] || false
}

export const getCompanyPermissions = (state, companyId) => {
  const permissionsByCompany = state.companies.get('permissionsByCompany') || {}
  return permissionsByCompany[companyId] || []
}

export const isGetCompanyPermissionsLoading = (state, companyId) => {
  const permissionsByCompanyLoading = state.companies.get('permissionsByCompanyLoading') || {}
  return permissionsByCompanyLoading[companyId] || false
}

export const isCompanySuccessfullyUpdated = state => state.companies.get('updateCompanySucceed')

export const isCompanyRegisterLoading = state => !!state.companies.get('registerIsLoading')

export const getCompanyPositions = (state, companyId) => (state.companies.get('positions') || {})[companyId] || []
export const isCompanyPositionsLoading = (state, companyId) => !!((state.companies.get('positionsLoading') || {})[companyId])

export const getCompanyDataUsage = (state, companyId) => (state.companies.get('dataUsageByCompany') || {})[companyId] || {}
export const isCompanyDataUsageLoading = (state, companyId) => !!((state.companies.get('getCompanyDataUsageIsLoading') || {})[companyId])

export const isTokenValid = (state, token) => !!state.companies.get('isTokenValid')[token]
export const isCheckTokenLoading = (state, token) => !!state.companies.get('checkTokenIsLoading')[token]
