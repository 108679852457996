const DEFAULT_PERMISSIONS = {
  delete: false,
  update: false,
  add: false,
  read: false,
}
export const getUserArtifactPermissions = user => {
  return user ? user.artifactPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserProjectPermissions = user => {
  return user ? user.projectPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserPipelinePermissions = user => {
  return user ? user.pipelinePermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserMissionPermissions = user => {
  return user ? user.missionPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getUserProjectCRSPermissions = user => {
  return user ? user.projectCRSPermissions || DEFAULT_PERMISSIONS : DEFAULT_PERMISSIONS
}

export const getFullUserName = (user, withEmail = false) => {
  return user.first_name + ' ' + user.last_name + (withEmail ? ` (${user.email})` : '')
}

export const getAddress = data => {
  return [
    data.city.replace(/,/, '').trim(),
    data.street.replace(/,/, '').trim(),
    data.state.replace(/,/, '').trim(),
    data.zip.replace(/,/, '').trim(),
  ].join(', ')
}

export const getSystemSerialNumber = data => {
  return data.systemSerial.map(serialNumber => serialNumber.replace(/,/, '').trim()).join(', ')
}
